import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { TextField, Checkbox, FormControlLabel } from "@mui/material";

export default function DynamicStateForm(props) {
  const theme = useTheme();
  return ( 
    <React.Fragment>
      <Typography variant="h3" gutterBottom color = { theme.palette.grey[700]}>
      What is your data folder path for the current state?
      </Typography>
    
      <Grid container spacing={3} sx={{ mb: 2, mt: 1 }}>
        <Grid item xs={12} sm={1}>
          <TextField
            required
            id="dynamicState"
            type="number"
            value={props.dynamicState}
            onChange={props.handleChangeState}
            label="Sate"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            required
            id="metadata"
            value={props.metadata}
            onChange={props.handleChangeMetadata}
            label="Folder URL"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
       
      </Grid>
      <Typography variant="h5" gutterBottom gutterBottom color = { theme.palette.primary.main} sx={{ fontSize:17 }} >
      You can have multiple rules for the same state (Ex. different behaviours depending the location for state X)
      </Typography>
      <br />
      <Typography variant="h6" gutterBottom  sx={{fontStyle: 'italic', fontSize:15 }} >
       * Folder URL can be updated / added later too
        </Typography>
      <Typography variant="h6" gutterBottom  sx={{fontStyle: 'italic', fontSize:15 }} >
       * The folder path must contains the metadata JSON's for the current state
      </Typography>
      
    </React.Fragment>
  );
}
