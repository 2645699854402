// action - customization reducer
export const SET_MENU = '@appState/SET_MENU';
export const MENU_TOGGLE = '@appState/MENU_TOGGLE';
export const MENU_OPEN = '@appState/MENU_OPEN';
export const MENU_DEFAULT = '@appState/MENU_DEFAULT';
export const CONTRACT_OPEN = '@appState/CONTRACT_OPEN'
export const SET_CONTRACT = '@appState/SET_CONTRACT'

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_ERROR = "SIGNIN_ERROR";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNOUT_ERROR = "SIGNOUT_ERROR";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";
export const EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED";