import * as React from "react";
//import { useTheme } from "@mui/material/styles";

import { CardContent, Typography } from "@mui/material";
import {Table, TableBody, TableCell,TableHead,TableRow} from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import Title from "ui-component/extended/Title";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const {MentaportTypes} = require( "@mentaport/sdk-web")
const geos = require('util/geos-major')

export default function ListTriggers(props) {
  //const theme = useTheme();
  console.log("List trigger")
  console.log(props)
  const ShowTime = ({ time }) => {
   
    if (time.idType ===  MentaportTypes.TimesType.timeWindow) {
      return (
        <>
        <Typography gutterBottom>{`Start Date: ${time.startTime.toLocaleString()}`}</Typography>
        <Typography gutterBottom>{`End Date: ${time.endTime.toLocaleString()}`}</Typography>
        </>
      );
    }
    if (time.idType ===  MentaportTypes.TimesType.startTime) {
      return (
        <>
        <Typography gutterBottom>{`Start Date: ${time.startTime.toLocaleString()}`}</Typography>
        </>
      );
    }
    return <Typography gutterBottom>NA</Typography>;
  }

  const ShowLocation = ({ location }) => {

    var address = location.info;
   
    var address1 = "";
    if (location.idType ===  MentaportTypes.LocationType.none) {
      address = "Anywhere"
      address1 = "";
    }
    else if (location.idType ===  MentaportTypes.LocationType.radius) {
      address = "";
      address1 = "radius: ";
      for(let i = 0; i < location.coordinates.length; i++){
        address +=location.coordinates[i].latitude + ", " + location.coordinates[i].longitude;
        address1 += location.coordinates[i].radius;
      }
    }
    else if (location.idType ===  MentaportTypes.LocationType.keywords) {
      address = "radius: ";
      for(let i = 0; i < location.coordinates.length; i++) {
        address1 +="[" +location.coordinates[i].latitude + ", " + location.coordinates[i].longitude+"]";
        address += location.coordinates[i].radius;
      }
    }
    else if (location.idType ===  MentaportTypes.LocationType.country) {
      address = geos.getCountryName(location.info);
    }
    else if (location.idType ===  MentaportTypes.LocationType.state) {
      const array = location.info.split(",");
      address = array[0] + " " + geos.getCountryName(array[1]);
    }
    else if (location.idType ===  MentaportTypes.LocationType.city) {
      const array = location.info.split(",");
      address =  array[0] + " " +  array[1];
      address1 = geos.getCountryName(array[2]);
    } 
    else if (location.idType ===  MentaportTypes.LocationType.address) {
      const array = location.info.split(",");
      address =  array[0] + " " + array[1] + " " + array[2] + " " + array[3];
      address1 = geos.getCountryName(array[4]);
    } 
      return (
        <>
          <Typography sx={{ textTransform: "capitalize"}}>{address}</Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {address1}
          </Typography>
        </>
      );
  };

  return (
    <MainCard content={false} boxShadow={true} sx={{m:5}}>
      <CardContent>
        <Title>{props.name}</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
            <TableCell>ID</TableCell> 
              <TableCell>{props.mainItem}</TableCell> 
              <TableCell>When </TableCell>
              <TableCell>Where</TableCell>
              <TableCell>Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.list.map((value) => (
              <TableRow key={value.id}>
                <TableCell>
                <Typography variant="caption">{value.id}</Typography>
                </TableCell>
              {value.access!== undefined ? (
                <TableCell sx={{ textTransform: "capitalize" }} >{value.access.idType}</TableCell>) :
                <>
                <TableCell>
                  <Typography>{value.state}</Typography>
                  <Typography variant="caption" display="block">{`Metadata:`}</Typography>
                  <Typography variant="caption" display="block">{value.metadata}</Typography>
                </TableCell> 
                </>
              }

                <TableCell>
                  <ShowTime time={value.time} />
                </TableCell>
                {value.location ? (
                  <TableCell>
                    <ShowLocation location={value.location} />
                    {value.location.amount >0 ? (
                       <Typography gutterBottom>{`Amount: ${value.location.amount}`}</Typography>
                    ) : <></>}
                  </TableCell>
                ) :<></> }
                <TableCell>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </MainCard>
  );
}
