// assets
import { IconCode,IconFileSearch, IconHelp } from '@tabler/icons';

// constant
const icons = { IconCode,IconFileSearch, IconHelp };

// ==============================|| UTILITY MENU ITEMS ||============================== //

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'api',
            title: 'API',
            type: 'item',
            url: '/apidoc',
            icon: icons.IconCode,
            iscontract:false
        },
        {
            id: 'documentation',
            title: 'Documentation',
            type: 'item',
            url: '/comingsoon',
            icon: icons.IconFileSearch,
            iscontract:false
        },
        {
            id: 'faq',
            title: 'FAQ',
            type: 'item',
            url: '/comingsoon_',
            icon: icons.IconHelp,
            //external: true,
            ///target: true
        }
    ]
};

export default utilities;


// // assets
// import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// // constant
// const icons = {
//     IconTypography,
//     IconPalette,
//     IconShadow,
//     IconWindmill
// };

// // ==============================|| UTILITIES MENU ITEMS ||============================== //

// const utilities = {
//     id: 'utilities',
//     title: 'Utilities',
//     type: 'group',
//     children: [
//         {
//             id: 'util-typography',
//             title: 'Typography',
//             type: 'item',
//             url: '/utils/util-typography',
//             icon: icons.IconTypography,
//             breadcrumbs: false
//         },
//         {
//             id: 'util-color',
//             title: 'Color',
//             type: 'item',
//             url: '/utils/util-color',
//             icon: icons.IconPalette,
//             breadcrumbs: false
//         },
//         {
//             id: 'util-shadow',
//             title: 'Shadow',
//             type: 'item',
//             url: '/utils/util-shadow',
//             icon: icons.IconShadow,
//             breadcrumbs: false
//         },
//         {
//             id: 'icons',
//             title: 'Icons',
//             type: 'collapse',
//             icon: icons.IconWindmill,
//             children: [
//                 {
//                     id: 'tabler-icons',
//                     title: 'Tabler Icons',
//                     type: 'item',
//                     url: '/icons/tabler-icons',
//                     breadcrumbs: false
//                 },
//                 {
//                     id: 'material-icons',
//                     title: 'Material Icons',
//                     type: 'item',
//                     url: '/icons/material-icons',
//                     breadcrumbs: false
//                 }
//             ]
//         }
//     ]
// };

// export default utilities;
