import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';
import { Image } from 'mui-image'

// project imports
// import config from '../../../config';
import config from 'config';

//import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        {/* <Logo /> */}
        <Image src="/images/logo_pink.png"  width={200} sx={{  display: { xs: 'none', md: 'flex' }  ,  ml:2}}/>

    </ButtonBase>
);

export default LogoSection;
