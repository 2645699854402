import * as React from "react";
import {List, ListItem, ListItemText, Grid, Typography} from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import ReviewTime from "ui-component/reviews/ReviewTime";
import ReviewLocation from "ui-component/reviews/ReviewLocation";

export default function ReviewMintRules(props) {
  const theme = useTheme();

  return (
    <React.Fragment>
     
      <Grid item xs={12} sm={6}>
        <Typography variant="h3" gutterBottom sx={{ mt: 2 }} color = { theme.palette.grey[700]}>
          Access Control
        </Typography>
        <Typography gutterBottom sx={{ textTransform: "capitalize" }}>{props.who}</Typography>
      </Grid>
      <ReviewTime timing={props.timing} startDate={props.startDate} endDate={props.endDate}  />
      <ReviewLocation location={props.location} coordinates={props.coordinates} radius={props.radius} locationDrop={props.locationDrop} />
    
    </React.Fragment>
  );
}
