import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage";

// ==============================|| FIREBASE SETUP  ||============================== //

const firebaseConfig = {
    apiKey: "AIzaSyAyk0sLUk1lG8Xu6UAKoDEUQJ1HEtUMMq4",
    authDomain: "mentaport-dev.firebaseapp.com",
    databaseURL: "https://mentaport-dev-default-rtdb.firebaseio.com",
    projectId: "mentaport-dev",
    storageBucket: "mentaport-dev.appspot.com",
    messagingSenderId: "139348463067",
    appId: "1:139348463067:web:6615561c8fe0f15326ebfe",
    measurementId: "G-Q5NENRNYS5"
  };

// Initialize Firebase and Firebase Authentication
firebase.initializeApp(firebaseConfig)
//firebase.firestore().settings({ timestampsInSnapshots: true, merge: true });

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app)
const storageCode = getStorage(app, "contract_code_snippets");

export {firebase, app, auth, db, storageCode};
