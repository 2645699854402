import * as React from "react";
import {Grid, Typography} from "@mui/material";

import { useTheme } from "@mui/material/styles";
const {MentaportTypes} = require( "@mentaport/sdk-web")
const geos = require('util/geos-major')

export default function ReviewLocations(props) {
  const theme = useTheme();
 
  function ShowLocation() {
    if (props.locationDrop === MentaportTypes.LocationType.none) {
     return (
        <>
          <Typography gutterBottom>Anywhere</Typography>
        </>
      );
    }
    if (props.locationDrop === MentaportTypes.LocationType.country) {
      const country = geos.getCountryName(props.location.country);
      return (
        <>
          <Typography gutterBottom>{`Country: ${country}`}</Typography>
        </>
      );
    }
    if (props.locationDrop === MentaportTypes.LocationType.city) {

      const country = geos.getCountryName(props.location.country);
      return (
        <>
          <Typography gutterBottom sx={{ textTransform: "capitalize"}} >{`City: ${props.location.city}`}</Typography>
          <Typography gutterBottom>{`Country: ${country}`}</Typography>
          <Typography gutterBottom>{`State: ${props.location.state}`}</Typography>
        </>
      );
    }
    if (props.locationDrop === MentaportTypes.LocationType.state) {
      const country = geos.getCountryName(props.location.country);
      return (
        <>
          <Typography gutterBottom>{`State: ${props.location.state}`}</Typography>
          <Typography gutterBottom>{`Country: ${country}`}</Typography>
        </>
      );
    }
    if (props.locationDrop === MentaportTypes.LocationType.address) {
      const country = geos.getCountryName(props.location.country);
      return (
        <>
          <Typography gutterBottom>{`Address: ${props.location.address}`}</Typography>
          <Typography gutterBottom>{`City: ${props.location.city}`}</Typography>
          <Typography gutterBottom>{`Country: ${country}`}</Typography>
          <Typography gutterBottom>{`State: ${props.location.state}`}</Typography>
          <Typography gutterBottom>{`Zipcode: ${props.location.zipcode}`}</Typography>
          <Typography gutterBottom >{`Radius: ${props.radius}`}</Typography>
        </>
      );
    }
    if (props.locationDrop === MentaportTypes.LocationType.radius) {
      return (
        <>
          <Typography gutterBottom >{`Coordinates:`}</Typography>
          <Typography gutterBottom >{props.coordinates}</Typography>
          <Typography gutterBottom >{`Radius: ${props.radius}`}</Typography>
        </>
      );
    }
    if (props.locationDrop === MentaportTypes.LocationType.keywords) {
      return (
        <>
          <Typography gutterBottom >{`Keyword Name: ${props.location.keyword}`}</Typography>
          <Typography gutterBottom >{`Coordinates:`}</Typography>
          <Typography gutterBottom >{props.coordinates}</Typography>
          <Typography gutterBottom >{`Radius: ${props.radius}`}</Typography>
        </>
      );
    }

    return <> </>;
  }

  return (
    <Grid item xs={12}>
        <Typography variant="h3" gutterBottom sx={{ mt: 2 }} color = { theme.palette.grey[700]}>
          Location Triggers
        </Typography>
        <ShowLocation />
        {(props.location.items > 0 ?
        (<Typography gutterBottom>{`Amount: ${props.location.items}`}</Typography> ) : <> </>)}
    </Grid>
  );
}
