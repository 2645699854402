import dashboard from './dashboard';
import modules from './modules';
 import {pages, PagesContracts} from './contracts';
//import pages from './contracts';

import utilities from './utilities';

import other from './other';
import {GetUsersContracts} from 'server/Database';

// ==============================|| MENU ITEMS ||============================== //

const SetMenuItems = async(userId) => {
    if(userId === -1) {
        return  {
            itemsDefault: [pages, utilities, other]
        };
    }
  
    const contractsMap = await GetUsersContracts(userId);
    const contracts = PagesContracts(contractsMap);

    const menuItems = {
        itemsDefault: [contracts, utilities, other]
    };

    return menuItems;
}


//export default SetMenuItems;
const menuItems = {
    itemsDefault: [utilities, other],
    items: [dashboard, modules, utilities, other]
};
// const menuItems = {
//     itemsDefault: [utilities, other],
//     items: [dashboard, modules, utilities, other]
// };
export {menuItems, SetMenuItems};
