import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { lucario } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { sample, SelectSnippet } from "./code_snippets";
import { useTheme } from "@mui/material/styles";
import myData from 'assets/files/tempMint.sol';


export default function CodeDetailsForm(props) {
  const theme = useTheme();

  const [usecase, changeUsecase] = React.useState("mint");
  const [codePath, setCodePath] = React.useState(props.codeSnippets["Mint"]);
  const [codeSnippet, setCodeSnittep] = React.useState("Comming soon");

  const [lineNumbers, toggleLineNumbers] = React.useState(true);
  // ---------------------------------------------------------

  fetch(myData)
  .then((response) => 
    fetch(response.url).then((r)=>{r.text().then( d=> setCodeSnittep(d))})
  )


  const handleFile = (file)=>{

    fetch(file)
    .then((response) => 
      fetch(response.url).then((r)=>{r.text().then( d=> setCodeSnittep(d))})
    )
  
    // var reader = new FileReader();
    //   reader.onload = function(e) {
    //       var content = reader.result;
    //       //Here the content has been read successfuly
    //       alert(content);
    //     }
    //     reader.readAsText('../../assets/files/tempMint.sol');    
    // console.log("dsds " + file)
    // setCodePath(file)
    // fileReader = new FileReader();
    // fileReader.onloaded = handleFileRead;
    // fileReader.readAsText(file);
    // reader.onload = async (file) => { 
    //   console.log(file)
    //   const text = (file.target.result)
    //    console.log(text)
    //    setCodeSnittep(text);
    
    // };
  }

  // const showFile = async (e) => {
  //   e.preventDefault()
  //   const reader = new FileReader()
  //   reader.onload = async (e) => { 
  //     const text = (e.target.result)
  //     console.log(text)
  //     alert(text)
  //   };
  //   reader.readAsText(e.target.files[0])
  // }
  
  return (
    <React.Fragment >
      <Typography variant="h2" gutterBottom sx={{ ml:1, mb: 3 }} color = { theme.palette.grey[700]} >
        Code Details
      </Typography>
      <Typography variant="h3" gutterBottom sx={{ ml: 1, mb: 3, fontStyle: 'italic' }}  color = { theme.palette.primary.main} >
      Current code integation will be done by us for you! 
      </Typography>
      <SelectSnippet
        usecase={{
          value: usecase,
          onChange: e => {
            handleFile(props.codeSnippets[e.target.value]);
            return changeUsecase(e.target.value);
          },
          options: Object.keys(props.codeSnippets).map(codeSnipp => (
            <option key={codeSnipp} value={codeSnipp}>
              {codeSnipp}
            </option>
          ))
        }}
      />
      <div className="demo" >
      <SyntaxHighlighter language="javascript" style={lucario}>
       {codeSnippet}
      </SyntaxHighlighter>
        <br />
       
      </div>
    </React.Fragment>
  );
}
