import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export default function ContractNameForm(props) {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom  >
        What is the name of your contract?
      </Typography>
      <Grid container spacing={3} sx={{ mb: 2, mt: 1 }}>
        <Grid item xs={12} sm={5}>
          <TextField
            required
            id="contractName"
            value={props.contractName}
            onChange={props.handleChangeContractName}
            label="Name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
            inputProps={{ style: { textTransform: "capitalize", fontSize: 20  } }}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            disabled
            control={<Checkbox defaultChecked />}
            label="Polygon Chain"
            sx={{fontSize: 20}}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
