import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

var {MentaportTypes} = require( "@mentaport/sdk-web")

export default function PrivacyTriggerForm(props) {
  const theme = useTheme();
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom color = { theme.palette.grey[700]}>
        Who can mint an NFT?
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="access-label" sx={{ mt: 1, mb:0, ml:-2}}>Who</InputLabel>
            <Select
              labelId="access-label"
              id="access-select"
              value={props.who}
              label="Access"
              sx= {{fontSize: 15}}
              onChange={props.handleChangeWho}
            >
              <MenuItem value={MentaportTypes.AccessType.public}>Public</MenuItem>
              {/* <MenuItem value={"Group"}>Group</MenuItem> */}
              <MenuItem value={MentaportTypes.AccessType.whitelist}>Allow List</MenuItem>
              <MenuItem value={MentaportTypes.AccessType.private}>Private</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} >
          <Button disableElevation sx={{ fontSize: '.9em'}}>
            Batch Upload (CSV)
            <ChevronRightOutlinedIcon />
          </Button>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
