import React from "react";
import { v4 as uuidv4 } from "uuid";

import { useTheme } from "@mui/material/styles";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import { Box, Grid , Button, Typography} from "@mui/material";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Stepper, Step, StepLabel } from "@mui/material";

import DynamicStateForm from "components/contracts/DynamicStateForm";
import TimeTriggerFrom from "components/contracts/TimeTriggerFrom";
import LocationTriggerForm from "components/contracts/LocationTriggerForm";
import ReviewDynamicRules from "components/contracts/ReviewDynamicRules";

import ListTriggers from "ui-component/ListTriggers";
import {GenerateTimeData, GenerateAccessData, GenerateLocationData} from "util/DataFormation"

const steps = ["State", "Time", "Location", "Review", "Finish"];
const initialList = [];

const listReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      return {
        ...state,
        list: state.list.concat({
          state: action.state,
          metadata:action.metadata,
          time: action.time,
          location: action.location,
          id: action.id,
        }),
      };
    default:
      throw new Error();
  }
};

export default function DynamicRules(props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  // variables of contracts:
  const [dynamicState, setDynamicState] = React.useState(0);
  const [metadata, setMetadata] = React.useState("");
  const [timing, setTiming] = React.useState("");
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date(startDate.getFullYear(), startDate.getMonth() + 1));
  const [locationDrop, setLocationDrop] = React.useState("");
 
  const [location, setLocation] = React.useState({
    latitude: "",
    longitude: "",
    radius:"",
    address: "",
    city: "",
    country: "us",
    state: "",
    zipcode: "",
    items:-10
  });


  const handleChangeState = (event) => {
    setDynamicState(event.target.value);
  };
  const handleChangeMetadata = (event) => {
    setMetadata(event.target.value);
  };
  const handleChangeTime = (event) => {
    setTiming(event.target.value);
  };

  const handleChangeStartTime = (event) => {
    setStartDate(event);
  };
  const handleChangeEndTime = (event) => {
    setEndDate(event);
  };
  const handleChangeLocationDrop = (event) => {
    setLocationDrop(event.target.value);
  };

  const handleChangeLocation = (value, input) => {
    setLocation({
      // spread in previous state with object spread operator
      ...location,
      [input]: value,
    });
  };
  //-------------------------------------------------------------------------

  function List(list) {
    const tmp = list.list;
    if (tmp.length > 0) {
      return <ListTriggers list={tmp} name ="Dynamic Triggers Added" mainItem="State" />;
    }
    return <> </>;
  }

  const [listData, dispatchListData] = React.useReducer(listReducer, {
    list: initialList
  });

  async function handleAddMoreRules() {
    console.log("ADD ANOTEHER RULE")
    setActiveStep(0);
  }

  //-------------------------------------------------------------------------
  async function addDynamicRules() {

    const time = GenerateTimeData(timing, startDate, endDate);
    const loc = GenerateLocationData(locationDrop, location, false);
   
   await dispatchListData({
      type: "ADD_ITEM",
      state:dynamicState,
      metadata,
      time,
      location:loc,
      id: uuidv4()
    });
 
    console.log(listData)
    handleNext();
 }

 function endMintRules() {
   console.log(listData)
   props.handleDynamicAdd(listData.list)  
 }
  //-------------------------------------------------------------------------

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
    const props = {
      location: location,
      handleChangeLocation: handleChangeLocation,
      locationDrop: locationDrop,
      handleChangeLocationDrop: handleChangeLocationDrop,
    };
    switch (step) {
      case 0:
        return (
          <DynamicStateForm dynamicState={dynamicState} metadata={metadata}
           handleChangeState={handleChangeState} 
           handleChangeMetadata ={handleChangeMetadata}
          />
        );
      case 1:
        return (
          <TimeTriggerFrom
           label="When can they upgrade their NFT?"
            timing={timing}
            startDate={startDate}
            endDate={endDate}
            handleChangeTime={handleChangeTime}
            handleChangeStartTime={handleChangeStartTime}
            handleChangeEndTime={handleChangeEndTime}
          />
        );
      case 2:
        return (
          <LocationTriggerForm
           label="Where can they upgrade their NFT?"
            location={location}
            handleChangeLocation={handleChangeLocation}
            locationDrop={locationDrop}
            handleChangeLocationDrop={handleChangeLocationDrop}
          />
        );
      case 3:
        return (
          <ReviewDynamicRules
            state={dynamicState}
            metadat={metadata}
            timing={timing}
            startDate={startDate}
            endDate={endDate}
            location={location}
            locationDrop={locationDrop}
           // handleAdd={handleAdd}
          />
        );
   
      default:
        throw new Error("Unknown step");
    }
  }

  return (
    <Card sx={{ border: "none" ,m:5}}>
      <CardHeader
        title={
          <Typography variant="h3">
           Dynamic NFT Upgrades (time & location)
          </Typography>
        }
      />
      <CardContent>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid container spacing={12} height={30} sx={{ mb: 2, mt: 1 }}></Grid>
        <React.Fragment>
          {activeStep === steps.length -1 ? (
            <React.Fragment>
                <List list={listData.list} />
                  <Fab size="medium" color="primary" aria-label="add" sx={{ mb: 2, mt: 2 }}>
                  <AddIcon onClick={handleAddMoreRules} />
                </Fab>
           
              <Grid
                container
                spacing={12}
                height={50}
                sx={{ mb: 2, mt: 1 }}
              ></Grid>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Back
                </Button> */}
                <Button variant="contained" fullWidth onClick= {endMintRules} sx={{ mt: 3, ml: 1, fontSize: 18 }} >
                Review Contract
                </Button>
              </Box>
            
            </React.Fragment>
        
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Grid
                container
                spacing={12}
                height={50}
                sx={{ mb: 2, mt: 1 }}
              ></Grid>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="outlined" onClick= {activeStep === 0 ? props.handleBack: handleBack}
                  sx={{ mt: 3, ml: 1, fontSize: 18,minWidth:100}}>
                  Back
                </Button>
                <Button 
                  variant="contained"
                  onClick= {activeStep === steps.length - 2 ? addDynamicRules: handleNext}
                  sx={{ mt: 3, ml: 3, fontSize: 18, minWidth:120}}
                >
                  {activeStep === steps.length - 2 ? "Add Rules" : "Next"}
                </Button>
              </Box>
              <Grid
                container
                spacing={12}
                height={50}
                sx={{ mb: 2, mt: 1 }}
              ></Grid>
              <List list={listData.list} />
            </React.Fragment>
          )}
        </React.Fragment>
      </CardContent>
    </Card>
  );
}
