import { 
    REGISTER_SUCCESS, REGISTER_ERROR,
    SIGNIN_SUCCESS, SIGNIN_ERROR,
    SIGNOUT_SUCCESS, SIGNOUT_ERROR,
    RESET_SUCCESS, RESET_ERROR,
    EMAIL_NOT_VERIFIED,

} from "./actions/types";

  const INITIAL_STATE = {
    authMsg: "",
    authState:""
  };
  
  export default function(state = INITIAL_STATE, action) {
   
    if (action.type === SIGNIN_SUCCESS || action.type === SIGNOUT_SUCCESS) {
      return { ...state, authMsg: "", authState:action.type };
    } else if (
      action.type === REGISTER_SUCCESS ||
      action.type === REGISTER_ERROR ||
      action.type === SIGNIN_ERROR ||
      action.type === EMAIL_NOT_VERIFIED ||
      action.type === SIGNOUT_ERROR ||
      action.type === RESET_SUCCESS ||
      action.type === RESET_ERROR
    ) {
      return { ...state, authMsg: action.payload, authState: action.type  };
    } else {
      return state;
    }
  }