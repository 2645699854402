export const themes = {
	a11yDark: 'a11y-dark',
	a11yLight: 'a11y-light',
	anOldHope: 'an-old-hope',
	androidstudio: 'androidstudio',
	arta: 'arta',
	atomOneDark: 'atom-one-dark',
	atomOneLight: 'atom-one-light',
	codepen: 'codepen',
	dracula: 'dracula',
	far: 'far',
	github: 'github',
	googlecode: 'googlecode',
	hopscotch: 'hopscotch',
	hybrid: 'hybrid',
	irBlack: 'ir-black',
	monoBlue: 'mono-blue',
	monokaiSublime: 'monokai-sublime',
	monokai: 'monokai',
	nord: 'nord',
	obsidian: 'obsidian',
	ocean: 'ocean',
	paraisoDark: 'paraiso-dark',
	paraisoLight: 'paraiso-light',
	pojoaque: 'pojoaque',
	purebasic: 'purebasic',
	railscast: 'railscast',
	rainbow: 'rainbow',
	shadesOfPurple: 'shades-of-purple',
	solarizedDark: 'solarized-dark',
	solarizedLight: 'solarized-light',
	sunburst: 'sunburst',
	tomorrowNightBlue: 'tomorrow-night-blue',
	tomorrowNightBright: 'tomorrow-night-bright',
	tomorrowNightEighties: 'tomorrow-night-eighties',
	tomorrowNight: 'tomorrow-night',
	tomorrow: 'tomorrow',
	vs2015: 'vs2015',
	xt256: 'xt256',
	zenburn: 'zenburn'
};

export { default as sample } from './codeblocks';
export { default as SelectSnippet } from './SelectSnippet';
