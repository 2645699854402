import { useRoutes} from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes'
import AuthenticationRoutes from './AuthenticationRoutes';

import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'


// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    
    const auth = useSelector(state => state.firebaseReducer.auth);
    //console.log(auth);
   // const authState = useSelector(state => state.authReducer);
    const isLoggedIn = isLoaded(auth) && !isEmpty(auth) && auth.emailVerified;
    
    const authRoute = AuthenticationRoutes(isLoggedIn)
    const mainRoute = MainRoutes(isLoggedIn)
    
    return useRoutes([authRoute, mainRoute]);
}

// const ThemeRoutes= () => {
//     return (
//         <>
//         <MainRoutes />
//         <Routes>
           
//             {/* <Route path="/" element={<Home />} />
//             <Route path="/dashboard" element={<Dashboard />} />
//             <Route path="/newcontract" element={<AddContract />} /> */}
//             <Route path="/login" element={<AuthLogin />} />
//             <Route path="/register" element={<AuthRegister />} />
//       </Routes>
//       </>
//     )
// }
// export default ThemeRoutes;