import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// project imports
import AuthWrapper1 from 'components/auth/AuthWrapper1';
import AuthCardWrapper from 'components/auth/AuthCardWrapper';
import AuthLogin from 'components/auth//auth-forms/AuthLogin';
import Footer from 'layout/MainLayout/Footer';

//import AuthFooter from 'ui-component/cards/AuthFooter';
import { Image } from 'mui-image'
import config from 'config'

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Login = () => {
    
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end"
             sx={{ minHeight: '100vh', backgroundImage: `url(${"/images/backgrounds/header_background.png"})`,   backgroundSize: 'cover' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 0 }}>
                                        <Link to="#">
                                            <img src="/images/logo_black.png"  width={200} sx={{  display: { xs: 'none', md: 'flex' },  ml:2}} />   
                                        </Link>
                                    </Grid>
                                    
                                    <Grid item xs={12}>
                                        <Stack alignItems="center" justifyContent="center" spacing={0}>
                                            <Typography variant="caption" fontSize="16px" justifyContent="center" textAlign={matchDownSM ? 'center' : 'inherit'} >
                                               Login to continue
                                            </Typography>
                                        </Stack>         
                                        <AuthLogin />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid item container direction="column" alignItems="center" xs={12}>
                                            <Typography
                                                component={'a'}
                                               // to={config.registerPath}
                                               href={'https://bba3u42bds1.typeform.com/to/adlvRDqM'}
                                                target="_blank"
                                                variant="subtitle1"
                                                sx={{ textDecoration: 'none' }}>
                                                Don&apos;t have an account?    Sign up
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                
                    <Footer />
                
            </Grid>
        </AuthWrapper1>
    );
};

export default Login;
