
import { app, db} from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import { collection, query, where, onSnapshot, addDoc } from "firebase/firestore";

import * as geofire from 'geofire-common';
import * as geofire1 from 'geofire';

export async function GetUsersContracts(userId) {
    const contractMap = [];

    const usersRef = doc(db, "users", userId);
    const docSnap = await getDoc(usersRef);
    
    if (docSnap.exists()) {
      //  console.log("Document data:", docSnap.data());
        const contracts = docSnap.data()['contracts'];
       
        for (const c of contracts) {
            const contractRef = doc(db, "contracts", c);
            const contSnap = await getDoc(contractRef);
            if (contSnap.exists()) {
               // console.log("Document contSnap:", contSnap.data());
                contractMap.push(
                {
                  "key":c,
                  "name":contSnap.data()['name'],
                  "info":contSnap.data()['contractCode']
                })
            }
        }
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    
    return contractMap;
}

export async function AddEmailWaitlist (email) {
   
    const data = {
        email: email,
    };
    try {
        const dbRef = collection(db, "waitlist");
        const docRef = await addDoc(dbRef, data);
        console.log("Document has been added successfully");
        return "You have been added successfully to the Waitlist!";
       
    } catch (error) {
        throw Error('We are having some difficulties at the moment, please come back later!')
        return 'fail'
    }
   
}

export async function GetContractCode (codeInfo) {
    //contract code not ready pull default:
    if(codeInfo === 'na' ) {
        return {"Mint" : "assets/files/tempMint.sol",
                "Dynamic" : "assets/files/tempMint.sol"}
    } else {
        return null;
    }
}

//-------------------------------------------
//-------------------------------------------

export async function GetNFTWithinRegion (handleAdd, center, radius) {
    console.log("GetNFTWithinRegion: ");
    if (typeof(center.latitude) == "undefined") {
        return;
    }

    const center_query = [center.latitude, center.longitude];
    console.log(center_query);
    const ref = app.database().ref('GeoList');
    var geoFireInstance = new geofire1.GeoFire(ref);
    
    var geoQuery = geoFireInstance.query({
        center: center_query,
        radius: radius
    });

    var onKeyEnteredRegistration = geoQuery.on("key_entered", function(key, location) {
       // console.log(key + " entered the query. Hi " + key + "!");
        //console.log(location);
        handleAdd(key, location);
    });
 }

 export async function GetNFT(key) {
    const ref = app.database().ref('NFTCards').child(key);
    const nft = await ref.once('value');
    return JSON.parse(nft.val());

 }

export async function GetNFTWithinRegionHash (handleAdd, center, radius) {
    console.log("GetNFTWithinRegionHash: ");
    console.log(center.latitude)
    if (typeof(center) == "undefined") {

        console.log('center')
        return;
    }
   // console.log("longitude " + longitude);
    // var firebaseVals = await loadFirebase();
     const center_query = [center.latitude, center.longitude];
     console.log(center_query);
  
     const ref = app.database().ref('GeoList');//.orderByChild("g");//where(new firebase.firestore.FieldPath('type' , 'panaderia'), '==', true);
     const ref1 = app.database().ref('GeoList').orderByChild("g");//where(new firebase.firestore.FieldPath('type' , 'panaderia'), '==', true);

    const bounds = geofire.geohashQueryBounds(center_query, radius);
    // for (const b of bounds) {
    //     ref.orderByChild('g').startAt(b[0]).endAt(b[1]).on('child_added', (snapshot) => {
    //         console.log("Sss")
    //         console.log(snapshot.key);
    //     });
    // }
    const geoFireInstance = new geofire1.GeoFire(ref);
    const geoQuery = geoFireInstance.query({
        center: center_query,
        radius: radius
      });
      const onKeyEnteredRegistration = geoQuery.on("key_entered", function(key, location) {
        console.log(key + " entered the query. Hi " + key + "!");
        console.log(location);
        handleAdd(key, location);
      
      });

    const promises = [];
    // for (const b of bounds) {
    //     const q = Firebase.database().ref('GeoList')
    //         .orderByChild('g')
    //         .startAt(b[0])
    //         .endAt(b[1]);
    //         promises.push(q.get());
    // }
    // Collect all the query results together into a single list
    Promise.all(promises).then((snapshots) => {
        const matchingDocs = [];
        console.log("snaposhotp");
        console.log(snapshots)

        for (const snap of snapshots) {
            console.log(snap)
            for (const doc of snap.docs) {
                const lat = doc.get('lat');
                const lng = doc.get('lng');
        
                // We have to filter out a few false positives due to GeoHash
                // accuracy, but most will match
                const distanceInKm = geofire.distanceBetween([lat, lng], center_query);
                const distanceInM = distanceInKm * 1000;
                if (distanceInM <= radius) {
                matchingDocs.push(doc);
                }
            }
        }
    
        return matchingDocs;
    }).then((matchingDocs) => {
    // Process the matching documents
    // ...
    console.log("matchingDocs");
    console.log(matchingDocs);
    });
     //  var geoFireInstance = new geofire.GeoFire(ref);

    //  //const snapshot = await ref.val();
    //  const field = 'l';
    //  const query = await geoFireInstance.query({
    //     center: [center.latitude, center.longitude],
    //     radius: radius
    //   });//(center, radius, field);
     
    //  return await geoFireInstance.get(query);


     // Attach event callbacks to the query
  
 }
 


