// assets
import {IconUserOff} from '@tabler/icons';

// constant
const icons = { IconUserOff };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'others',
    type: 'group',
    children: [
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            url: 'logout',
            icon: icons.IconUserOff,
            iscontract:false
        }
    ]
};

export default other;
