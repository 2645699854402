// assets
import { IconWriting } from '@tabler/icons';

// constant
const icons = {
    IconWriting
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    title: 'New Contract Modules',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'mint_contracts',
            title: 'Mint Fencing (access, time & location)',
            type: 'item',
            icon: icons.IconWriting,
             url: '/newcontract',
            //url: '/comingsoon_',
            iscontract:false
        },
        {
            id: 'upgrade_contracts',
            title: 'Dynamic NFT Upgrades (time & location)',
            type: 'item',
            icon: icons.IconWriting,
            // url: '/newcontract',
            url: '/comingsoon_',
            iscontract:false
        }
    ]
};

export default pages;
