import * as React from "react";
import {List, ListItem, ListItemText, Grid, Typography} from "@mui/material/";
import ReviewTime from "ui-component/reviews/ReviewTime";
import ReviewLocation from "ui-component/reviews/ReviewLocation";
import { useTheme } from "@mui/material/styles";

const {MentaportTypes} = require( "@mentaport/sdk-web")

export default function ReviewDynamicRules(props) {
  const theme = useTheme();

  function ShowState() {
    if(props.state !== "") {
      return (
      <>
       <Typography variant="h3" gutterBottom sx={{ mt: 2 }} color = { theme.palette.grey[700]}>
        State
        </Typography>
        <Typography gutterBottom> {`#  ${props.state}`}</Typography>
        <Typography gutterBottom>Metadata: {props.metadata}</Typography>
      </>
        )
    }
    return <> </>;
  }

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6}>
      <ShowState />
      </Grid>
      <ReviewTime timing={props.timing} startDate= {props.startDate} endDate={props.endDate} />
      <ReviewLocation location={props.location} locationDrop={props.locationDrop} />
    </React.Fragment>
  );
}
