import React from "react";
import { v4 as uuidv4 } from "uuid";

import { useTheme } from "@mui/material/styles";
import { Link } from 'react-router-dom';

// import CssBaseline from '@mui/material/CssBaseline';
import { Box, Grid , Button, Typography} from "@mui/material";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Stepper, Step, StepLabel } from "@mui/material";

import ContractNameForm from "components/contracts/ContractNameForm";
import MintRules from "components/contracts/MintRules";
import DynamicRules from "components/contracts/DynamicRules";
import MuiLoadingButton from "ui-component/buttons/MuiLoadingButton";

import CodeDetailsForm from "components/contracts/CodeDetailsForm";
import ListTriggers from "ui-component/ListTriggers";
import config from 'config';

import { LoadingButton } from '@mui/lab'
import SaveIcon from '@mui/icons-material/Save'
import { Stack } from '@mui/material'
//------ SDK ---------
import {generateNewContract} from "server/SDKCalls";
//--------------------

const steps = ["Name", "Mint Rules", "Dynamic Rules", "Review"];

export default function AddContract() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  // variables of contracts:
  const [contractName, setContractName] = React.useState("");
  const [mintRules, setMintRules] = React.useState([]);
  const [dynamicRules, setDynamicRules] = React.useState([]);

  // added contract Vars:
  const [reviewContract, setReviewContract] = React.useState(false);

  const [laodingContract, setLoadingContract] = React.useState(false);
  const [addedContract, setAddedContract] = React.useState(false);
  const [errorContract, setErrorContract] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);

  const [newContract, setNewContract] = React.useState("");

  //-------------------------------------------------------------------------
  const handleChangeContractName = (event) => {
    setContractName(event.target.value);
  };

  const handleMintAdd = (listData) => {
    console.log("AddContract: handleMintAdd")
    console.log(listData);

    setMintRules(listData)
    setActiveStep(activeStep +1);
  }
  const handleDynamicAdd = (listData) => {
    console.log("AddContract: handleDynamicAdd")
    console.log(listData);

    setDynamicRules(listData)
    setActiveStep(activeStep + 1);
    setReviewContract(true);
  }
  const handleEndNoDynamic = (listData) => {
    console.log("handleEndNoDynamic: handleMintAdd")
    console.log(listData);

    setMintRules(listData)
    setActiveStep(activeStep + 2);
    setReviewContract(true);
  }
  
  //-------------------------------------------------------------------------
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setReviewContract(false);
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {
   
    switch (step) {
      case 0:
        return (
          <Card sx={{ border: "none" , ml:3, mr:3}}>
           <CardContent > 
            <ContractNameForm
              contractName={contractName}
              handleChangeContractName={handleChangeContractName}
            />
           </CardContent>
          </Card>
        );
      case 1:
        return (
          <MintRules 
          handleMintAdd={handleMintAdd} handleBack={handleBack} handleEndNoDynamic={handleEndNoDynamic}
           />
        ); 
      case 2:
        return (
          <DynamicRules 
          handleDynamicAdd={handleDynamicAdd} handleBack={handleBack}
           />
        );
      case 3:
        return (<></>
          // <Review
          //   contractName={contractName}
           
          // />
        );
      default:
        throw new Error("Unknown step");
    }
  }
  //-------------------------------------------------------------------------

  function ListMint() { 
    if (mintRules !== undefined && mintRules.length > 0) {
      return <ListTriggers list={mintRules} name = "Mint Triggers Added" mainItem="Who"/>;
    }
    return <> </>;
  }
  function ListDynamic(list) {
    if (dynamicRules !== undefined && dynamicRules.length > 0) {
      return <ListTriggers list={dynamicRules} name ="Dynamic Triggers Added" mainItem="State" />;
    }
    return <> </>;
  }

  //-------------------------------------------------------------------------
  function addContract () {
    setLoadingContract(true)
    generateNewContract(contractName, mintRules, dynamicRules).then((res) => {
    
      console.log(res)
      console.log(res.status)
     
      setLoadingContract(false)
      if(res.status === 'Success') {
        setAddedContract(true)
        setNewContract(res.data.idKey);
      } else {
        // error
        setErrorMessage(res.body.message);
        setErrorContract(true)
        
      }
    });
  }
  function  ReviewState() {
    
    if(addedContract) {
      return ( <AddedContract /> );
    }
    if(errorContract) {
      return ( <ErrorAddingContract /> );
    
    }
    return ( <ReviewContract /> );
  }

  function ErrorAddingContract() {
    return (
      <Card sx={{ m: 5 }}>
        <Grid item  xs={12} sx={{ m: 5 }}>
        <Typography variant="h2" sx={{ mb: 3 }}>Error adding your contract</Typography>

        <Typography variant="h4" gutterBottom color = { theme.palette.error.main}>
        {errorMessage}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
         
          <Button variant="contained" component={Link} to={config.defaultPath} fullWidth sx={{ mt: 3, ml: 1}}>
            {"Sorry, try again"}
          </Button>
        </Box>
      </Grid>
      
      </Card>
    );

  }
  function AddedContract() {
    return (
      <Card sx={{ m: 5 }}>
        <Grid item  xs={12} sx={{ m: 5 }}>
        <Typography variant="h2" sx={{ mb: 3 }}>Added Contract Successfully!</Typography>

        <Typography variant="h3" sx={{ mb: 4  }}>IdKey : <Box sx={{ fontStyle: 'italic', fontSize: 16 }} color = { theme.palette.grey[700]} display='inline'>{newContract}</Box></Typography>


        <Typography variant="h4" gutterBottom color = { theme.palette.primary.main}>
          Your code snippets will be ready soon!
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
         
          <Button variant="contained" component={Link} to={config.defaultPath} fullWidth sx={{ mt: 3, ml: 1}}>
            {"Finish"}
          </Button>
        </Box>
      </Grid>
      
      </Card>
    );

  }
  function ReviewContract() {
    return (
      
        <Card sx={{ border: "none" ,  m:3}}>
        <CardHeader
          title={
            <Typography variant="h3">Review Contract <Box sx={{ fontStyle: 'italic',  textTransform: "capitalize"  }} display='inline'>'{contractName}'</Box> :</Typography>
          }
        />
        <CardContent>
          <Grid item  xs={12} sx={{ ml: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          {laodingContract ?
            (
              <Stack direction='row' spacing={2}>
                 <LoadingButton loading loadingIndicator='Cancel' variant='outlined'>
                 Cancel
                </LoadingButton> <br/>
                <LoadingButton loading variant='outlined'  startIcon={<SaveIcon />} loadingPosition='start'>
                {" Add Contract"}
                </LoadingButton>
            </Stack>
            ) :<>
                <Button variant= "outlined" onClick={handleBack} sx={{ mt: 2, ml: 1, minWidth: 120  }}>
                  {"Cancel"}
                </Button>
              
              <Button variant="contained" onClick={addContract} sx={{ mt: 2, ml: 3, minWidth: 200 }}>
                {"Add Contract"}
              </Button>
            </>
          }
          </Box>
        </Grid>
        <Grid item  xs={12} sx={{ m: 3 }}>
          <ListMint />
        </Grid>
        <Grid item  xs={12} sx={{ m: 3 }}>
          <ListDynamic />
        </Grid>
         <Typography variant="h5" gutterBottom>
            * Your code snippets will be ready soon after adding contract
          </Typography>
      </CardContent>
      </Card>
     
    );

  }
  //-------------------------------------------------------------------------
  return (
    <React.Fragment >
      {!reviewContract ? (
     <Card sx={{ border: "none" ,  m: 3 }}>
      <CardHeader
        title={
          <Typography variant="h2">
            Adding New Contract
          </Typography>
        }
      />
      <CardContent>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5}} >
          {steps.map((label) => (
            <Step key={label} >
              <StepLabel >{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        </CardContent>
        
    </Card>) : <></>}
    
        <React.Fragment>
       
          {activeStep === steps.length -1 ? (
            <React.Fragment>
             <ReviewState />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              {/* <Grid container spacing={12} height={50} sx={{ mb: 2, mt: 1, mr:5 }}></Grid> */}
              
              {(activeStep !== 1 && activeStep !== 2) ? (
              <Box sx={{ display: "flex", justifyContent: "flex-end", m:5 }}>
             
                {activeStep !== 0 && (
                  <Button onClick={handleBack} sx={{ mt: 3, ml: 1, fontSize: 20 }}>
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={handleNext}
                  sx={{ mt: 3, ml: 1, fontSize: 18, minWidth:100}}
                >
                  {activeStep === steps.length - 1 ? "Review Contract" : "Next"}
                </Button> 
              </Box>
              ): <></>
            }
              {/* <Grid
                container
                spacing={12}
                height={50}
                sx={{ mb: 2, mt: 1 }}
              ></Grid>
               */}
            </React.Fragment>
          )}
        </React.Fragment>
      

  </React.Fragment>
  );
}
