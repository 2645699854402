import React from "react";
import { v4 as uuidv4 } from "uuid";

import { useTheme } from "@mui/material/styles";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import { Box, Grid , Button, Typography} from "@mui/material";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Stepper, Step, StepLabel } from "@mui/material";


import PrivacyTriggerForm from "components/contracts/WhoTriggerForm";
import TimeTriggerFrom from "components/contracts/TimeTriggerFrom";
import LocationTriggerForm from "components/contracts/LocationTriggerForm";
import ReviewMintRules from "components/contracts/ReviewMintRules";

import SimpleList from "ui-component/SimpleList";
import ListTriggers from "ui-component/ListTriggers";
import {GenerateTimeData, GenerateAccessData, GenerateLocationData} from "util/DataFormation"

const steps = ["Access", "Time", "Location", "Review", "Finish"];
const initialMintRuleList = [];
const initialPositionList = []

const listReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      return {
        ...state,
        list: state.list.concat({
          access: action.access,
          time: action.time,
          location: action.location,
          id: action.id,
        }),
      };
    default:
      throw new Error();
  }
};

const listPositionReducer = (state, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      return {
        ...state,
        list: state.list.concat({
          latitude: action.latitude,
          longitude: action.longitude,
          radius: action.radius,
        }),
      };
    default:
      throw new Error();
  }
};

export default function MintRules(props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  // variables of contracts:
  const [who, setWho] = React.useState("");
  const [timing, setTiming] = React.useState("");
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date(startDate.getFullYear(), startDate.getMonth() + 1));
  const [locationDrop, setLocationDrop] = React.useState("");
  const [checkedItems, setCheckedItems] = React.useState(true);

  const [latLong, setLatLong] = React.useState({
    latitude: "",
    longitude: "",
    radius:""
  });
  const [location, setLocation] = React.useState({
    address: "",
    city: "",
    country: "us",
    state: "",
    zipcode: "",
    keyword:"",
    items:0
  });

  const handleChangeWho = (event) => {
    setWho(event.target.value);
  };

  const handleChangeTime = (event) => {
    console.log(event.target.value)
    setTiming(event.target.value);
  };

  const handleChangeStartTime = (event) => {
    setStartDate(event);
  };
  const handleChangeEndTime = (event) => {
    setEndDate(event);
  };
  const handleChangeLocationDrop = (event) => {
    setLocationDrop(event.target.value);
  };

  const handleChangeLocation = (value, input) => {
    setLocation({
      // spread in previous state with object spread operator
      ...location,
      [input]: value,
    });
  };
  const handleChangeLatLong = (value, input) => {
    setLatLong({
      // spread in previous state with object spread operator
      ...latLong,
      [input]: value,
    });
  };
  async function addNewPosition() {

    await dispatchListPositionData({
      type: "ADD_ITEM",
      latitude:latLong.latitude,
      longitude: latLong.longitude,
      radius:latLong.radius,
      id: uuidv4(),
    });

    setLatLong( {latitude: "", longitude: "", radius:""});
  }

  //-------------------------------------------------------------------------

  function List(list) {
    const tmp = list.list;
    if (tmp!= undefined && tmp.length > 0) {
      return <ListTriggers list={tmp} name = "Mint Triggers Added" mainItem="Who"/>;
    }
    return <> </>;
  }
  function TempList(list) {

    const tmp = list.list;
    if (tmp!= undefined && tmp.length > 0) {
      return <SimpleList list={tmp} name = "Coordinates" />;
    }
    return <> </>;
  }

  const [listMintRuleData, dispatchListData] = React.useReducer(listReducer, {
    list: initialMintRuleList
  });

  const [listPositionData, dispatchListPositionData] = React.useReducer(listPositionReducer, {
    list: initialPositionList
  });
  async function handleAddMoreRules() {
    console.log("ADD ANOTEHER RULE")
    setActiveStep(0);
  }

  //-------------------------------------------------------------------------
  
async function addMintRules() {
   
  const time = GenerateTimeData(timing, startDate, endDate);
  const access = GenerateAccessData(who, []);
  var  list = listPositionData.list;
  if(listPositionData.list == undefined || listPositionData.list.length == 0) {
    list = [latLong]
  }
  const loc = GenerateLocationData(locationDrop, location, checkedItems, list);

  await dispatchListData({
    type: "ADD_ITEM",
    access,
    time,
    location:loc,
    id: uuidv4(),
  });
    
  //console.log(listMintRuleData)
  handleNext();
}

 function endMintRules() {
   console.log(listMintRuleData)
   props.handleMintAdd(listMintRuleData.list)  
 }
 function endAllRules() {
    props.handleEndNoDynamic(listMintRuleData.list);
 }
  //-------------------------------------------------------------------------

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function getStepContent(step) {

    switch (step) {
      case 0:
        return (
          <PrivacyTriggerForm who={who} handleChangeWho={handleChangeWho} />
        );
      case 1:
        return (
          <TimeTriggerFrom
            label="When can they mint an NFT?"
            timing={timing}
            startDate={startDate}
            endDate={endDate}
            handleChangeTime={handleChangeTime}
            handleChangeStartTime={handleChangeStartTime}
            handleChangeEndTime={handleChangeEndTime}
          />
        );
      case 2:
        return (
          <>
          <LocationTriggerForm
            label="Where can they mint an NFT?"
            checkedItems={checkedItems}
            setCheckedItems = {setCheckedItems}
            location={location}
            latLong={latLong}
            handleChangeLatLong={handleChangeLatLong}
            handleChangeLocation={handleChangeLocation}
            locationDrop={locationDrop}
            handleChangeLocationDrop={handleChangeLocationDrop}
            addNewPosition={addNewPosition}
          />
          {locationDrop === "keywords" ? ( 
            <TempList list={listPositionData.list} />) :<></>}
          </>
        );
      case 3:
      var coordinates = latLong.latitude + "," + latLong.longitude;
      var radius= latLong.radius;
        if(listPositionData.list != undefined && listPositionData.list.length  > 0) {
          coordinates = "";
          radius = ""
          for(let i = 0; i < listPositionData.list.length; i++){
            coordinates +="[" + listPositionData.list[i].latitude + ", " + listPositionData.list[i].longitude +"]";
            radius += listPositionData.list[i].radius + ", ";
          }
        }
        var x = latLong.l;
        return (
          <ReviewMintRules
            who={who}
            timing={timing}
            startDate={startDate}
            endDate={endDate}
            location={location}
            coordinates={coordinates}
            radius={radius}
            locationDrop={locationDrop}
          />
        );
   
      default:
        throw new Error("Unknown step");
    }
  }

  return (
    <Card sx={{ border: "none" ,m:3}}>
      <CardHeader
        title={
          <Typography variant="h3" >
            Mint Rules (access, time & location)
          </Typography>
        }
      />
      <CardContent>
        <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid container spacing={12} height={30} sx={{ mb: 2, mt: 1 }}></Grid>
        <React.Fragment>
          {activeStep === steps.length -1 ? (
            <React.Fragment>
                <List list={listMintRuleData.list} />
                  <Fab size="medium" color="primary" aria-label="add" sx={{ mb: 2, mt: 2 }}>
                  <AddIcon onClick={handleAddMoreRules} />
                </Fab>
           
              {/* <Grid container spacing={12} height={50}
                sx={{ mb: 2, mt: 1 }} ></Grid> */}
              <Grid container direction="column" sx={{ display: "flex", justifyContent: "flex-end" }}>
                {/* <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                  Back
                </Button> */}
                 <Button variant="contained" color = "secondary" fullWidth onClick= {endMintRules} sx={{ mt: 3, ml: 1, fontSize: 18}} >
                  Add Dynamic Rules
                </Button>
                <Button variant="outlined"  fullWidth onClick= {endAllRules} sx={{ mt: 3, ml: 1, fontSize: 18}} >
                 Finish Contract
                </Button>
               
              </Grid>
            
            </React.Fragment>
        
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Grid
                container
                spacing={12}
                height={50}
                sx={{ mb: 2, mt: 1 }}
              ></Grid>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button variant="outlined" onClick= {activeStep === 0 ? props.handleBack: handleBack}
                  sx={{ mt: 3, ml: 1, fontSize: 18 , minWidth:100}}>
                  Back
                </Button>
                <Button 
                  variant="contained"
                  onClick= {activeStep === steps.length - 2 ? addMintRules: handleNext}
                  sx={{ mt: 3, ml: 3, fontSize: 18,minWidth:120}}
                >
                  {activeStep === steps.length - 2 ? "Add Rules" : "Next"}
                </Button>
              </Box>
              <Grid
                container
                spacing={12}
                height={50}
                sx={{ mb: 2, mt: 1 }}
              ></Grid>
              <List list={listMintRuleData.list} />
            </React.Fragment>
          )}
        </React.Fragment>
      </CardContent>
    </Card>
  );
}
