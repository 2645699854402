import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  MobileDateTimePicker,
  DateTimePicker,
} from "@mui/x-date-pickers/MobileDateTimePicker";
import { Button } from "@mui/material";

// assets
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
const {MentaportTypes} = require( "@mentaport/sdk-web")

// ---------------------------------------------------------
// Function to determine what to show for timing trigger

const ShowTimePicker = React.memo(({ props }) => {
  console.log(props.timing)
  if (props.timing === MentaportTypes.TimesType.timeWindow) {
    return (
      <>
        <Grid item xs={12} sm={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              value={props.startDate}
              
              onChange={(newValue) => {
                props.handleChangeStartTime(newValue);
              }}
              label="Start Date"
              onError={console.log}
              minDate={new Date("2018-01-01T00:00")}
              inputFormat="yyyy/MM/dd hh:mm a"
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} sm={2}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker
              value={props.endDate}
              onChange={(newValue) => {
                props.handleChangeEndTime(newValue);
              }}
              label="End Date"
              onError={console.log}
              minDate={new Date("2018-01-01T00:00")}
              inputFormat="yyyy/MM/dd hh:mm a"
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
          </LocalizationProvider>
        </Grid>
      </>
    );
  } else if (props.timing === MentaportTypes.TimesType.startTime) {
    return (
      <>
        <Grid item xs={12} sm={4} >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDateTimePicker 
              value={props.startDate}
              onChange={(newValue) => {
                props.handleChangeStartTime(newValue);
              }}
              label="Start Date"
              onError={console.log}
              minDate={new Date("2018-01-01T00:00")}
              inputFormat="yyyy/MM/dd hh:mm a"
              renderInput={(params) => <TextField  {...params} variant="standard" />}
            />
          </LocalizationProvider>
        </Grid>
      </>
    );
  }
  return (
    <Grid item xs={12} sm={4}>
      {" "}
    </Grid>
  );
});

export default function TimeTriggerFrom(props) {
  const theme = useTheme();
  // ---------------------------------------------------------
  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom  color = { theme.palette.grey[700]}>
      {props.label}
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={4} >
          <FormControl fullWidth >
            <InputLabel id="timing-label" sx={{ mt: 1, ml:-2}}>When</InputLabel>
            <Select
              labelId="timing-label"
              id="timing-select"
              value={props.timing}
              label="Timing" sx= {{fontSize: 15}}
              onChange={props.handleChangeTime}
            >
              <MenuItem value={MentaportTypes.TimesType.timeWindow}>Time Window</MenuItem>
              <MenuItem value={MentaportTypes.TimesType.startTime}>Start Date</MenuItem>
              <MenuItem value={MentaportTypes.TimesType.none}>Any Time</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Button disableElevation sx={{ fontSize: '.9em'}}>
            Batch Upload (CSV)
            <ChevronRightOutlinedIcon />
          </Button>
        </Grid>
        <ShowTimePicker props={props} />
      </Grid>
    </React.Fragment>
  );
}
