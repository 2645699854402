export default function componentStyleOverrides(theme) {
    const bgColor = theme.colors?.grey50;
    console.log(theme)
    return {
        // MuiTableCell:{
        //     styleOverrides: {
        //         root: {
        //             fontWeight: 800,
        //         }
        //     }
        // },
        // MuiFormControl: {
        //     styleOverrides: {
        //         root: {
        //             fontWeight: 510,
        //             fontSize: '1.3rem',
        //             borderRadius: '30px',
        //            padding:'10px'
        //         }
        //     }
        // },
        //  MuiStepLabel:{
        //     styleOverrides: {
        //         root: {
                
        //           fontSize: '20px',
        //            //padding:'5px'
        //         }
        //     }
        // },
        MuiStepper:{
            styleOverrides: {
                root: {
                  fontSize: '.5em',
                  ['@media (min-width:780px)']: {
                    fontSize: '1.5em',
                  },
                   //padding:'5px'
                }
            }
        },
        MuiStepIcon:{
            styleOverrides: {
                root: {
                    fontSize: '1.4em',
                    ['@media (min-width:780px)']: {
                      fontSize: '1.5em',
                    },
                  
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 510,
                    fontSize: '1.3rem',
                    borderRadius: '30px',
                   padding:'10px'
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                },
                rounded: {
                    borderRadius: '12px'
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    color: theme.colors?.textDark,
                    padding: '24px'
                },
                title: {
                    fontSize: '1.125rem'
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '24px'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    '&.Mui-selected': {
                        color: theme.menuSelected,
                        backgroundColor: theme.menuSelectedBack,
                        '&:hover': {
                            backgroundColor: theme.menuSelectedBack
                        },
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    },
                    '&:hover': {
                        backgroundColor: theme.menuSelectedBack,
                        color: theme.menuSelected,
                        '& .MuiListItemIcon-root': {
                            color: theme.menuSelected
                        }
                    }
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: theme.darkTextPrimary,
                    minWidth: '36px'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    color: theme.textDark
                    
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: theme.textDark,
                    '&::placeholder': {
                        color: theme.darkTextSecondary,
                        fontSize: '0.875rem'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root:{
                paddingLeft:25,
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: bgColor,
                    borderRadius: '12px',
                    paddingLeft:22,
                    paddingBottom:0,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: theme.colors?.grey400,
                        borderRadius:  '25px',
                    },
                    '&:hover $notchedOutline': {
                        borderColor: theme.colors?.primaryLight
                    },
                    '&.MuiInputBase-multiline': {
                        padding: 1
                    }
                },
                input: {
                    fontWeight: 500,
                    background: bgColor,
                    padding: '25.5px 14px',
                    borderRadius:  '12px',
                    '&.MuiInputBase-inputSizeSmall': {
                        padding: '10px 14px',
                        '&.MuiInputBase-inputAdornedStart': {
                            paddingLeft: 0
                        }
                    }
                },
                inputAdornedStart: {
                    paddingLeft: 4
                },
                // notchedOutline: {
                //     borderRadius:  '25px',
                   
                // }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        color: theme.colors?.grey300
                    }
                },
                mark: {
                    backgroundColor: theme.paper,
                    width: '4px'
                },
                valueLabel: {
                    color: theme?.colors?.primaryLight
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: theme.divider,
                    opacity: 1
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    color: theme.colors?.primaryDark,
                    background: theme.colors?.primary200
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-deletable .MuiChip-deleteIcon': {
                        color: 'inherit'
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    color: theme.paper,
                    background: theme.colors?.grey700
                }
            }
        }
    };
}
