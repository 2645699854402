import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

import spec  from 'assets/api/mentaport.yaml';

const APIdoc = () => {
    return (
      <div style={{background:"#F4F8FB"}}>
       <SwaggerUI url={spec} />
      </div>
    );
  };
  
export default APIdoc;


