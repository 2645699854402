import { combineReducers } from 'redux';
import {firebaseReducer} from 'react-redux-firebase'

// reducer import
import appStateReducer from './appStateReducer';
import authReducer from './authReducer';


// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    firebaseReducer,
    authReducer,
    appState: appStateReducer
});

export default reducer;
