import React from 'react';
import Select from '@mui/material/Select';
import {MenuItem, InputLabel, FormControl} from '@mui/material';

export default function(props) {

	const usecase = props.usecase;
	return (
    <FormControl sx={{ mb: 1, minWidth: 200 }}>
				<InputLabel id="code-label"sx={{ mt: 1, ml:-2}}>Code</InputLabel>
	        <Select
              labelId="code-label"
              id="code-select"
              value={usecase.value}
              label="code"
              onChange={usecase.onChange}>
			{usecase.options.map((row) => (
				<MenuItem key={row.props.value} value={row.props.value} > {row.props.value}</MenuItem>
			))}   
      </Select>
   </FormControl>
	
	);
}
