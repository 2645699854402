// ===========================|| DASHBOARD - TOTAL ORDER YEAR CHART ||=========================== //

const chartData = {
    type: 'line',
    height: 90,
    options: {
        chart: {
            sparkline: {
                enabled: true
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ['#fff'],
        fill: {
            type: 'solid',
            opacity: 1
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        yaxis: {
            min: 0,
            max: 1300
        },
        tooltip: {
            theme: 'dark',
            fixed: {
                enabled: false
            },
            x: {
                show: true
            },
            y: {
                title: 'Total Order'
            },
            marker: {
                show: true
            }
        }
    },
    series: [
        {
            name: 'Num. Interactions',
            data: [283, 247, 262, 268, 294, 192, 276, 276, 243, 226, 227, 187, 164, 154, 180, 206, 152, 100, 261, 289, 358, 518, 696, 509, 441, 313, 245, 314, 330, 340, 236, 383, 402, 469, 443, 524, 602, 718, 538, 571, 724, 712, 645, 600, 391, 315, 278, 315, 375, 468, 482, 653, 857, 867, 381, 426, 327, 429, 427, 512, 512, 493, 592, 637, 651, 611, 712, 653, 762, 782, 822, 1027, 1000, 1072, 739, 712, 795, 675, 536, 526, 641, 820, 952, 991, 790, 648, 617, 692, 724, 716, 811, 878, 851, 948, 717, 826, 907, 912, 896, 926, 1006, 1055, 1099, 1096]
        }
    ]
};

export default chartData;
