import React from 'react';
import { useTheme } from "@mui/material/styles";
import {Grid, Button, Typography} from '@mui/material';
import { Link } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

function Home() {
    const theme = useTheme();

    function requestAPIKey() {
        alert("Coming soon")
    }
    return (
        <Grid justifyContent="center" sx={{ flexGrow: 2 }}   container spacing={4}>  
            <Grid item xs={12} sx={{ m: 3 }} >
            <Typography variant="h1" component="div" align='center' color = { theme.palette.grey[700]}  >
                Welcome to Mentaport Beta Portal
            </Typography>
           
           </Grid>
            <Grid item >
                <Card sx={{ minWidth: 400 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 25}} color="text.secondary" gutterBottom>
                        API
                        </Typography>
                        <Typography variant="h5" component="div">
                        Where the dynamic NFT's come to life!
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        REST API
                        </Typography>
                        <Typography variant="body2">
                       Init your smart contracts
                        <br />
                       Talk with your NFTs
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" component={Link} to='/apidoc'  >Learn More</Button>
                    </CardActions>
                </Card>
             </Grid>
             <Grid item >
                <Card sx={{ minWidth: 400 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 25 }} color="text.secondary" gutterBottom>
                        SDK
                        </Typography>
                        <Typography variant="h5" component="div">
                         Location Proofing 
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Web and Mobile
                        </Typography>
                        <Typography variant="body2">
                        Our SDK does location proofind for web and mobile 
                        <br />
                        so you get peace of mind when using our location API
                    
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" component={Link} to='/comingsoon'>Learn More</Button>
                    </CardActions>
                </Card>
             </Grid>
             <Grid item >
                <Card sx={{ minWidth: 400 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 25 }} color="text.secondary" gutterBottom>
                        Smart Contracts
                        </Typography>
                        <Typography variant="h5" component="div">
                        Blockchains
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Etherium, Poligon
                        </Typography>
                        <Typography variant="body2">
                       Currently on testnest and moving fast for Mainet.
                        <br />
                        Partnering with Mystenlabs for our next release.                   
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" component={Link} to='/comingsoon'> Learn More</Button>
                    </CardActions>
                </Card>
             </Grid>
             <Grid item xs={12} sx={{ m: 3 }}>
                <Typography variant="h2" component="div"  align='center' color = { theme.palette.primary.main}>
                    "Bring your smart contracts to the real world"
                </Typography>
          
            {/* <Button variant="contained" onClick={requestAPIKey}sx={{ mt: 3, ml: 1 }}>
                Request API KEY
            </Button> */}
           </Grid>
           

        </Grid>
    );
}

export default Home; 