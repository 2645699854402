'use strict';

var data = {};

data.country = {
  "af": { countryCode: 'AF', countryName: 'Afghanistan', currency: 'AFN', continent: 'Asia', subContinent: 'Southern Asia', phoneCode: '93', latitude: 33, longitude: 65 },
  "al": { countryCode: 'AL', countryName: 'Albania', currency: 'ALL', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '355', latitude: 41, longitude: 20 },
  "aq": { countryCode: 'AQ', countryName: 'Antarctica', phoneCode: 'null', latitude: -90, longitude: 0 },
  "dz": { countryCode: 'DZ', countryName: 'Algeria', currency: 'DZD', continent: 'Africa', subContinent: 'Northern Africa', phoneCode: '213', latitude: 28, longitude: 3 },
  "as": { countryCode: 'AS', countryName: 'American Samoa', currency: 'USD', continent: 'Oceania', subContinent: 'Polynesia', phoneCode: '1684', latitude: -14.3333, longitude: -170 },
  "ad": { countryCode: 'AD', countryName: 'Andorra', currency: 'EUR', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '376', latitude: 42.5, longitude: 1.5 },
  "ao": { countryCode: 'AO', countryName: 'Angola', currency: 'AOA', continent: 'Africa', subContinent: 'Middle Africa', phoneCode: '244', latitude: -12.5, longitude: 18.5 },
  "ag": { countryCode: 'AG', countryName: 'Antigua and Barbuda', currency: 'XCD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1268', latitude: 17.05, longitude: -61.8 },
  "az": { countryCode: 'AZ', countryName: 'Azerbaijan', currency: 'AZN', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '994', latitude: 40.5, longitude: 47.5 },
  "ar": { countryCode: 'AR', countryName: 'Argentina', currency: 'ARS', continent: 'Americas', subContinent: 'South America', phoneCode: '54', latitude: -34, longitude: -64 },
  "au": { countryCode: 'AU', countryName: 'Australia', currency: 'AUD', continent: 'Oceania', subContinent: 'Australia,New Zealand', phoneCode: '61', latitude: -27, longitude: 133 },
  "at": { countryCode: 'AT', countryName: 'Austria', currency: 'EUR', continent: 'Europe', subContinent: 'Western Europe', phoneCode: '43', latitude: 47.3333, longitude: 13.3333 },
  "bs": { countryCode: 'BS', countryName: 'Bahamas', currency: 'BSD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1242', latitude: 24.25, longitude: -76 },
  "bh": { countryCode: 'BH', countryName: 'Bahrain', currency: 'BHD', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '973', latitude: 26, longitude: 50.55 },
  "bd": { countryCode: 'BD', countryName: 'Bangladesh', currency: 'BDT', continent: 'Asia', subContinent: 'Southern Asia', phoneCode: '880', latitude: 24, longitude: 90 },
  "am": { countryCode: 'AM', countryName: 'Armenia', currency: 'AMD', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '374', latitude: 40, longitude: 45 },
  "bb": { countryCode: 'BB', countryName: 'Barbados', currency: 'BBD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1246', latitude: 13.1667, longitude: -59.5333 },
  "be": { countryCode: 'BE', countryName: 'Belgium', currency: 'EUR', continent: 'Europe', subContinent: 'Western Europe', phoneCode: '32', latitude: 50.8333, longitude: 4 },
  "bm": { countryCode: 'BM', countryName: 'Bermuda', currency: 'BMD', continent: 'Americas', subContinent: 'Northern America', phoneCode: '1441', latitude: 32.3333, longitude: -64.75 },
  "bt": { countryCode: 'BT', countryName: 'Bhutan', currency: 'BTN,INR', continent: 'Asia', subContinent: 'Southern Asia', phoneCode: '975', latitude: 27.5, longitude: 90.5 },
  "bo": { countryCode: 'BO', countryName: 'Bolivia', currency: 'BOB,BOV', continent: 'Americas', subContinent: 'South America', phoneCode: '591', latitude: -17, longitude: -65 },
  "ba": { countryCode: 'BA', countryName: 'Bosnia and Herzegovina', currency: 'BAM', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '387', latitude: 44, longitude: 18 },
  "bw": { countryCode: 'BW', countryName: 'Botswana', currency: 'BWP', continent: 'Africa', subContinent: 'Southern Africa', phoneCode: '267', latitude: -22, longitude: 24 },
  "bv": { countryCode: 'BV', countryName: 'Bouvet Island', currency: 'NOK', phoneCode: 'null', latitude: -54.4333, longitude: 3.4 },
  "br": { countryCode: 'BR', countryName: 'Brazil', currency: 'BRL', continent: 'Americas', subContinent: 'South America', phoneCode: '55', latitude: -10, longitude: -55 },
  "bz": { countryCode: 'BZ', countryName: 'Belize', currency: 'BZD', continent: 'Americas', subContinent: 'Central America', phoneCode: '501', latitude: 17.25, longitude: -88.75 },
  "io": { countryCode: 'IO', countryName: 'British Indian Ocean Territory', currency: 'USD', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '246', latitude: -6, longitude: 71.5 },
  "sb": { countryCode: 'SB', countryName: 'Solomon Islands', currency: 'EUR', continent: 'Oceania', subContinent: 'Melanesia', phoneCode: '677', latitude: -8, longitude: 159 },
  "vg": { countryCode: 'VG', countryName: 'Virgin Islands (British)', currency: 'USD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1284', latitude: 18.5, longitude: -64.5 },
  "bn": { countryCode: 'BN', countryName: 'Brunei Darussalam', currency: 'BND', continent: 'Asia', subContinent: 'South-Eastern,Asia', phoneCode: '673', latitude: 4.5, longitude: 114.6667 },
  "bg": { countryCode: 'BG', countryName: 'Bulgaria', currency: 'BGN', continent: 'Europe', subContinent: 'Eastern Europe', phoneCode: '359', latitude: 43, longitude: 25 },
  "mm": { countryCode: 'MM', countryName: 'Myanmar (Burma)', currency: 'MMK', continent: 'Asia', subContinent: 'South-Eastern,Asia', phoneCode: '95', latitude: 22, longitude: 98 },
  "bi": { countryCode: 'BI', countryName: 'Burundi', currency: 'BIF', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '257', latitude: -3.5, longitude: 30 },
  "by": { countryCode: 'BY', countryName: 'Belarus', currency: 'BYR', continent: 'Europe', subContinent: 'Eastern Europe', phoneCode: '375', latitude: 53, longitude: 28 },
  "kh": { countryCode: 'KH', countryName: 'Cambodia', currency: 'KHR', continent: 'Asia', subContinent: 'South-Eastern,Asia', phoneCode: '855', latitude: 13, longitude: 105 },
  "cm": { countryCode: 'CM', countryName: 'Cameroon', currency: 'XAF', continent: 'Africa', subContinent: 'Middle Africa', phoneCode: '237', latitude: 6, longitude: 12 },
  "ca": { countryCode: 'CA', countryName: 'Canada', currency: 'CAD', continent: 'Americas', subContinent: 'Northern America', phoneCode: '1', latitude: 60, longitude: -95 },
  "cv": { countryCode: 'CV', countryName: 'Cape Verde', currency: 'CVE', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '238', latitude: 16, longitude: -24 },
  "ky": { countryCode: 'KY', countryName: 'Cayman Islands', currency: 'KYD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1345', latitude: 19.5, longitude: -80.5 },
  "cf": { countryCode: 'CF', countryName: 'Central African Republic', currency: 'XAF', continent: 'Africa', subContinent: 'Middle Africa', phoneCode: '236', latitude: 7, longitude: 21 },
  "lk": { countryCode: 'LK', countryName: 'Sri Lanka', currency: 'LKR', continent: 'Asia', subContinent: 'Southern Asia', phoneCode: '94', latitude: 7, longitude: 81 },
  "td": { countryCode: 'TD', countryName: 'Chad', currency: 'XAF', continent: 'Africa', subContinent: 'Middle Africa', phoneCode: '235', latitude: 15, longitude: 19 },
  "cl": { countryCode: 'CL', countryName: 'Chile', currency: 'CLF,CLP', continent: 'Americas', subContinent: 'South America', phoneCode: '56', latitude: -30, longitude: -71 },
  "cn": { countryCode: 'CN', countryName: 'China', currency: 'CNY', continent: 'Asia', subContinent: 'Eastern Asia', phoneCode: '86', latitude: 35, longitude: 105 },
  "tw": { countryCode: 'TW', countryName: 'Taiwan', currency: 'TWD', continent: 'Asia', subContinent: 'Eastern Asia', phoneCode: '886', latitude: 23.5, longitude: 121 },
  "cx": { countryCode: 'CX', countryName: 'Christmas Island', currency: 'AUD', continent: 'Oceania', subContinent: 'Australia,New Zealand', phoneCode: '61', latitude: -10.5, longitude: 105.6667 },
  "cc": { countryCode: 'CC', countryName: 'Cocos (Keeling) Islands', currency: 'AUD', continent: 'Oceania', subContinent: 'Australia,New Zealand', phoneCode: '61', latitude: -12.5, longitude: 96.8333 },
  "co": { countryCode: 'CO', countryName: 'Colombia', currency: 'COP,COU', continent: 'Americas', subContinent: 'South America', phoneCode: '57', latitude: 4, longitude: -72 },
  "km": { countryCode: 'KM', countryName: 'Comoros', currency: 'KMF', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '269', latitude: -12.1667, longitude: 44.25 },
  "yt": { countryCode: 'YT', countryName: 'Mayotte', currency: 'EUR', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '262', latitude: -12.8333, longitude: 45.1667 },
  "cg": { countryCode: 'CG', countryName: 'Republic of the Congo', currency: 'XAF', continent: 'Africa', subContinent: 'Middle Africa', phoneCode: '242', latitude: -1, longitude: 15 },
  "cd": { countryCode: 'CD', countryName: 'Democratic Republic of the Congo', currency: 'CDF', continent: 'Africa', subContinent: 'Middle Africa', phoneCode: '243', latitude: 0, longitude: 25 },
  "ck": { countryCode: 'CK', countryName: 'Cook Islands', currency: 'NZD', continent: 'Oceania', subContinent: 'Polynesia', phoneCode: '682', latitude: -21.2333, longitude: -159.7667 },
  "cr": { countryCode: 'CR', countryName: 'Costa Rica', currency: 'CRC', continent: 'Americas', subContinent: 'Central America', phoneCode: '506', latitude: 10, longitude: -84 },
  "hr": { countryCode: 'HR', countryName: 'Croatia', currency: 'HRK', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '385', latitude: 45.1667, longitude: 15.5 },
  "cu": { countryCode: 'CU', countryName: 'Cuba', currency: 'CUC,CUP', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '53', latitude: 21.5, longitude: -80 },
  "cy": { countryCode: 'CY', countryName: 'Cyprus', currency: 'EUR', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '357', latitude: 35, longitude: 33 },
  "cz": { countryCode: 'CZ', countryName: 'Czech Republic', currency: 'CZK', continent: 'Europe', subContinent: 'Eastern Europe', phoneCode: '420', latitude: 49.75, longitude: 15.5 },
  "bj": { countryCode: 'BJ', countryName: 'Benin', currency: 'XOF', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '229', latitude: 9.5, longitude: 2.25 },
  "dk": { countryCode: 'DK', countryName: 'Denmark', currency: 'DKK', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '45', latitude: 56, longitude: 10 },
  "dm": { countryCode: 'DM', countryName: 'Dominica', currency: 'XCD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1767', latitude: 15.4167, longitude: -61.3333 },
  "do": { countryCode: 'DO', countryName: 'Dominican Republic', currency: 'DOP', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1809,1829,1849', latitude: 19, longitude: -70.6667 },
  "ec": { countryCode: 'EC', countryName: 'Ecuador', currency: 'USD', continent: 'Americas', subContinent: 'South America', phoneCode: '593', latitude: -2, longitude: -77.5 },
  "sv": { countryCode: 'SV', countryName: 'El Salvador', currency: 'SVC,USD', continent: 'Americas', subContinent: 'Central America', phoneCode: '503', latitude: 13.8333, longitude: -88.9167 },
  "gq": { countryCode: 'GQ', countryName: 'Equatorial Guinea', currency: 'XAF', continent: 'Africa', subContinent: 'Middle Africa', phoneCode: '240', latitude: 2, longitude: 10 },
  "et": { countryCode: 'ET', countryName: 'Ethiopia', currency: 'ETB', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '251', latitude: 8, longitude: 38 },
  "er": { countryCode: 'ER', countryName: 'Eritrea', currency: 'ERN', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '291', latitude: 15, longitude: 39 },
  "ee": { countryCode: 'EE', countryName: 'Estonia', currency: 'EUR', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '372', latitude: 59, longitude: 26 },
  "fo": { countryCode: 'FO', countryName: 'Faroe Islands', currency: 'DKK', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '298', latitude: 62, longitude: -7 },
  "fk": { countryCode: 'FK', countryName: 'Falkland Islands (Malvinas)', currency: 'FKP', continent: 'Americas', subContinent: 'South America', phoneCode: '500', latitude: -51.75, longitude: -59 },
  "gs": { countryCode: 'GS', countryName: 'South Georgia', currency: 'ZAR', continent: 'Americas', subContinent: 'South America', phoneCode: '500', latitude: -54.5, longitude: -37 },
  "fj": { countryCode: 'FJ', countryName: 'Fiji', currency: 'FJD', continent: 'Oceania', subContinent: 'Melanesia', phoneCode: '679', latitude: -18, longitude: 175 },
  "fi": { countryCode: 'FI', countryName: 'Finland', currency: 'EUR', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '358', latitude: 64, longitude: 26 },
  "ax": { countryCode: 'AX', countryName: 'Åland Islands', currency: 'EUR', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '358', latitude: 60.15, longitude: 20 },
  "fr": { countryCode: 'FR', countryName: 'France', currency: 'EUR', continent: 'Europe', subContinent: 'Western Europe', phoneCode: '33', latitude: 46, longitude: 2 },
  "gf": { countryCode: 'GF', countryName: 'French Guiana', currency: 'EUR', continent: 'Americas', subContinent: 'South America', phoneCode: '594', latitude: 4, longitude: -53 },
  "pf": { countryCode: 'PF', countryName: 'French Polynesia', currency: 'XPF', continent: 'Oceania', subContinent: 'Polynesia', phoneCode: '689', latitude: -15, longitude: -140 },
  "tf": { countryCode: 'TF', countryName: 'French Southern Territories', currency: 'EUR', phoneCode: 'null', latitude: -43, longitude: 67 },
  "dj": { countryCode: 'DJ', countryName: 'Djibouti', currency: 'DJF', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '253', latitude: 11.5, longitude: 43 },
  "ga": { countryCode: 'GA', countryName: 'Gabon', currency: 'XAF', continent: 'Africa', subContinent: 'Middle Africa', phoneCode: '241', latitude: -1, longitude: 11.75 },
  "ge": { countryCode: 'GE', countryName: 'Georgia', currency: 'GEL', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '995', latitude: 42, longitude: 43.5 },
  "gm": { countryCode: 'GM', countryName: 'Gambia', currency: 'GMD', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '220', latitude: 13.4667, longitude: -16.5667 },
  "ps": { countryCode: 'PS', countryName: 'Palestinian Territory', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '970', latitude: 32, longitude: 35.25 },
  "de": { countryCode: 'DE', countryName: 'Germany', currency: 'EUR', continent: 'Europe', subContinent: 'Western Europe', phoneCode: '49', latitude: 51, longitude: 9 },
  "gh": { countryCode: 'GH', countryName: 'Ghana', currency: 'GHS', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '233', latitude: 8, longitude: -2 },
  "gi": { countryCode: 'GI', countryName: 'Gibraltar', currency: 'GIP', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '350', latitude: 36.1833, longitude: -5.3667 },
  "ki": { countryCode: 'KI', countryName: 'Kiribati', currency: 'AUD', continent: 'Oceania', subContinent: 'Micronesia', phoneCode: '686', latitude: 1.4167, longitude: 173 },
  "gr": { countryCode: 'GR', countryName: 'Greece', currency: 'EUR', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '30', latitude: 39, longitude: 22 },
  "gl": { countryCode: 'GL', countryName: 'Greenland', currency: 'DKK', continent: 'Americas', subContinent: 'Northern America', phoneCode: '299', latitude: 72, longitude: -40 },
  "gd": { countryCode: 'GD', countryName: 'Grenada', currency: 'XCD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1473', latitude: 12.1167, longitude: -61.6667 },
  "gp": { countryCode: 'GP', countryName: 'Guadeloupe', currency: 'EUR', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '590', latitude: 16.25, longitude: -61.5833 },
  "gu": { countryCode: 'GU', countryName: 'Guam', currency: 'USD', continent: 'Oceania', subContinent: 'Micronesia', phoneCode: '1671', latitude: 13.4667, longitude: 144.7833 },
  "gt": { countryCode: 'GT', countryName: 'Guatemala', currency: 'GTQ', continent: 'Americas', subContinent: 'Central America', phoneCode: '502', latitude: 15.5, longitude: -90.25 },
  "gn": { countryCode: 'GN', countryName: 'Guinea', currency: 'GNF', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '224', latitude: 11, longitude: -10 },
  "gy": { countryCode: 'GY', countryName: 'Guyana', currency: 'GYD', continent: 'Americas', subContinent: 'South America', phoneCode: '592', latitude: 5, longitude: -59 },
  "ht": { countryCode: 'HT', countryName: 'Haiti', currency: 'HTG,USD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '509', latitude: 19, longitude: -72.4167 },
  "hm": { countryCode: 'HM', countryName: 'Heard Island and McDonald Islands', currency: 'AUD', phoneCode: 'null', latitude: -53.1, longitude: 72.5167 },
  "va": { countryCode: 'VA', countryName: 'Vatican City', currency: 'EUR', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '39066,379', latitude: 41.9, longitude: 12.45 },
  "hn": { countryCode: 'HN', countryName: 'Honduras', currency: 'HNL', continent: 'Americas', subContinent: 'Central America', phoneCode: '504', latitude: 15, longitude: -86.5 },
  "hk": { countryCode: 'HK', countryName: 'Hong Kong', currency: 'HKD', continent: 'Asia', subContinent: 'Eastern Asia', phoneCode: '852', latitude: 22.25, longitude: 114.1667 },
  "hu": { countryCode: 'HU', countryName: 'Hungary', currency: 'HUF', continent: 'Europe', subContinent: 'Eastern Europe', phoneCode: '36', latitude: 47, longitude: 20 },
  "is": { countryCode: 'IS', countryName: 'Iceland', currency: 'ISK', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '354', latitude: 65, longitude: -18 },
  "in": { countryCode: 'IN', countryName: 'India', currency: 'INR', continent: 'Asia', subContinent: 'Southern Asia', phoneCode: '91', latitude: 20, longitude: 77 },
  "id": { countryCode: 'ID', countryName: 'Indonesia', currency: 'IDR', continent: 'Asia', subContinent: 'South-Eastern,Asia', phoneCode: '62', latitude: -5, longitude: 120 },
  "ir": { countryCode: 'IR', countryName: 'Iran', currency: 'IRR', continent: 'Asia', subContinent: 'Southern Asia', phoneCode: '98', latitude: 32, longitude: 53 },
  "iq": { countryCode: 'IQ', countryName: 'Iraq', currency: 'IQD', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '964', latitude: 33, longitude: 44 },
  "ie": { countryCode: 'IE', countryName: 'Ireland', currency: 'EUR', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '353', latitude: 53, longitude: -8 },
  "il": { countryCode: 'IL', countryName: 'Israel', currency: 'ILS', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '972', latitude: 31.5, longitude: 34.75 },
  "it": { countryCode: 'IT', countryName: 'Italy', currency: 'EUR', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '39', latitude: 42.8333, longitude: 12.8333 },
  "ci": { countryCode: 'CI', countryName: 'Côte d\'Ivoire', currency: 'XOF', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '225', latitude: 8, longitude: -5 },
  "jm": { countryCode: 'JM', countryName: 'Jamaica', currency: 'JMD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1876', latitude: 18.25, longitude: -77.5 },
  "jp": { countryCode: 'JP', countryName: 'Japan', currency: 'JPY', continent: 'Asia', subContinent: 'Eastern Asia', phoneCode: '81', latitude: 36, longitude: 138 },
  "kz": { countryCode: 'KZ', countryName: 'Kazakhstan', currency: 'KZT', continent: 'Asia', subContinent: 'Central Asia', phoneCode: '76,77', latitude: 48, longitude: 68 },
  "jo": { countryCode: 'JO', countryName: 'Jordan', currency: 'JOD', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '962', latitude: 31, longitude: 36 },
  "ke": { countryCode: 'KE', countryName: 'Kenya', currency: 'KES', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '254', latitude: 1, longitude: 38 },
  "kp": { countryCode: 'KP', countryName: 'North Korea', currency: 'KPW', continent: 'Asia', subContinent: 'Eastern Asia', phoneCode: '850', latitude: 40, longitude: 127 },
  "kr": { countryCode: 'KR', countryName: 'South Korea', currency: 'KRW', continent: 'Asia', subContinent: 'Eastern Asia', phoneCode: '82', latitude: 37, longitude: 127.5 },
  "kw": { countryCode: 'KW', countryName: 'Kuwait', currency: 'KWD', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '965', latitude: 29.3375, longitude: 47.6581 },
  "kg": { countryCode: 'KG', countryName: 'Kyrgyzstan', currency: 'KGS', continent: 'Asia', subContinent: 'Central Asia', phoneCode: '996', latitude: 41, longitude: 75 },
  "la": { countryCode: 'LA', countryName: 'Laos', currency: 'LAK', continent: 'Asia', subContinent: 'South-Eastern,Asia', phoneCode: '856', latitude: 18, longitude: 105 },
  "lb": { countryCode: 'LB', countryName: 'Lebanon', currency: 'LBP', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '961', latitude: 33.8333, longitude: 35.8333 },
  "ls": { countryCode: 'LS', countryName: 'Lesotho', currency: 'LSL,ZAR', continent: 'Africa', subContinent: 'Southern Africa', phoneCode: '266', latitude: -29.5, longitude: 28.5 },
  "lv": { countryCode: 'LV', countryName: 'Latvia', currency: 'LVL', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '371', latitude: 57, longitude: 25 },
  "lr": { countryCode: 'LR', countryName: 'Liberia', currency: 'LRD', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '231', latitude: 6.5, longitude: -9.5 },
  "ly": { countryCode: 'LY', countryName: 'Libya', currency: 'LYD', continent: 'Africa', subContinent: 'Northern Africa', phoneCode: '218', latitude: 25, longitude: 17 },
  "li": { countryCode: 'LI', countryName: 'Liechtenstein', currency: 'CHF', continent: 'Europe', subContinent: 'Western Europe', phoneCode: '423', latitude: 47.1667, longitude: 9.5333 },
  "lt": { countryCode: 'LT', countryName: 'Lithuania', currency: 'LTL', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '370', latitude: 56, longitude: 24 },
  "lu": { countryCode: 'LU', countryName: 'Luxembourg', currency: 'EUR', continent: 'Europe', subContinent: 'Western Europe', phoneCode: '352', latitude: 49.75, longitude: 6.1667 },
  "mo": { countryCode: 'MO', countryName: 'Macao', currency: 'MOP', continent: 'Asia', subContinent: 'Eastern Asia', phoneCode: '853', latitude: 22.1667, longitude: 113.55 },
  "mg": { countryCode: 'MG', countryName: 'Madagascar', currency: 'MGA', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '261', latitude: -20, longitude: 47 },
  "mw": { countryCode: 'MW', countryName: 'Malawi', currency: 'MWK', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '265', latitude: -13.5, longitude: 34 },
  "my": { countryCode: 'MY', countryName: 'Malaysia', currency: 'MYR', continent: 'Asia', subContinent: 'South-Eastern,Asia', phoneCode: '60', latitude: 2.5, longitude: 112.5 },
  "mv": { countryCode: 'MV', countryName: 'Maldives', currency: 'MVR', continent: 'Asia', subContinent: 'Southern Asia', phoneCode: '960', latitude: 3.25, longitude: 73 },
  "ml": { countryCode: 'ML', countryName: 'Mali', currency: 'XOF', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '223', latitude: 17, longitude: -4 },
  "mt": { countryCode: 'MT', countryName: 'Malta', currency: 'EUR', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '356', latitude: 35.8333, longitude: 14.5833 },
  "mq": { countryCode: 'MQ', countryName: 'Martinique', currency: 'EUR', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '596', latitude: 14.6667, longitude: -61 },
  "mr": { countryCode: 'MR', countryName: 'Mauritania', currency: 'MRO', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '222', latitude: 20, longitude: -12 },
  "mu": { countryCode: 'MU', countryName: 'Mauritius', currency: 'MUR', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '230', latitude: -20.2833, longitude: 57.55 },
  "mx": { countryCode: 'MX', countryName: 'Mexico', currency: 'MXN,MXV', continent: 'Americas', subContinent: 'Central America', phoneCode: '52', latitude: 23, longitude: -102 },
  "mc": { countryCode: 'MC', countryName: 'Monaco', currency: 'EUR', continent: 'Europe', subContinent: 'Western Europe', phoneCode: '377', latitude: 43.7333, longitude: 7.4 },
  "mn": { countryCode: 'MN', countryName: 'Mongolia', currency: 'MNT', continent: 'Asia', subContinent: 'Eastern Asia', phoneCode: '976', latitude: 46, longitude: 105 },
  "md": { countryCode: 'MD', countryName: 'Moldova', currency: 'MDL', continent: 'Europe', subContinent: 'Eastern Europe', phoneCode: '373', latitude: 47, longitude: 29 },
  "me": { countryCode: 'ME', countryName: 'Montenegro', currency: 'EUR', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '382', latitude: 42, longitude: 19 },
  "ms": { countryCode: 'MS', countryName: 'Montserrat', currency: 'XCD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1664', latitude: 16.75, longitude: -62.2 },
  "ma": { countryCode: 'MA', countryName: 'Morocco', currency: 'MAD', continent: 'Africa', subContinent: 'Northern Africa', phoneCode: '212', latitude: 32, longitude: -5 },
  "mz": { countryCode: 'MZ', countryName: 'Mozambique', currency: 'MZN', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '258', latitude: -18.25, longitude: 35 },
  "om": { countryCode: 'OM', countryName: 'Oman', currency: 'OMR', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '968', latitude: 21, longitude: 57 },
  "na": { countryCode: 'NA', countryName: 'Namibia', currency: 'NAD,ZAR', continent: 'Africa', subContinent: 'Southern Africa', phoneCode: '264', latitude: -22, longitude: 17 },
  "nr": { countryCode: 'NR', countryName: 'Nauru', currency: 'AUD', continent: 'Oceania', subContinent: 'Micronesia', phoneCode: '674', latitude: -0.5333, longitude: 166.9167 },
  "np": { countryCode: 'NP', countryName: 'Nepal', currency: 'NPR', continent: 'Asia', subContinent: 'Southern Asia', phoneCode: '977', latitude: 28, longitude: 84 },
  "nl": { countryCode: 'NL', countryName: 'Netherlands', currency: 'EUR', continent: 'Europe', subContinent: 'Western Europe', phoneCode: '31', latitude: 52.5, longitude: 5.75 },
  "an": { latitude: 12.25, longitude: -68.75, continent: 'North America' },
  "cw": { countryCode: 'CW', countryName: 'Curaçao', currency: 'ANG', phoneCode: '5999', latitude: 12.1833, longitude: -68.9333 },
  "aw": { countryCode: 'AW', countryName: 'Aruba', currency: 'AWG', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '297', latitude: 12.5, longitude: -69.9667 },
  "sx": { countryCode: 'SX', countryName: 'Sint Maarten', currency: 'ANG', phoneCode: '1721', latitude: 18.05, longitude: -63.08 },
  "bq": { countryCode: 'BQ', countryName: 'Bonaire', currency: 'USD', phoneCode: '5997', latitude: 17.48268, longitude: -62.98324 },
  "nc": { countryCode: 'NC', countryName: 'New Caledonia', currency: 'XPF', continent: 'Oceania', subContinent: 'Melanesia', phoneCode: '687', latitude: -21.5, longitude: 165.5 },
  "vu": { countryCode: 'VU', countryName: 'Vanuatu', currency: 'VUV', continent: 'Oceania', subContinent: 'Melanesia', phoneCode: '678', latitude: -16, longitude: 167 },
  "nz": { countryCode: 'NZ', countryName: 'New Zealand', currency: 'NZD', continent: 'Oceania', subContinent: 'Australia,New Zealand', phoneCode: '64', latitude: -41, longitude: 174 },
  "ni": { countryCode: 'NI', countryName: 'Nicaragua', currency: 'NIO', continent: 'Americas', subContinent: 'Central America', phoneCode: '505', latitude: 13, longitude: -85 },
  "ne": { countryCode: 'NE', countryName: 'Niger', currency: 'XOF', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '227', latitude: 16, longitude: 8 },
  "ng": { countryCode: 'NG', countryName: 'Nigeria', currency: 'NGN', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '234', latitude: 10, longitude: 8 },
  "nu": { countryCode: 'NU', countryName: 'Niue', currency: 'NZD', continent: 'Oceania', subContinent: 'Polynesia', phoneCode: '683', latitude: -19.0333, longitude: -169.8667 },
  "nf": { countryCode: 'NF', countryName: 'Norfolk Island', currency: 'AUD', continent: 'Oceania', subContinent: 'Australia,New Zealand', phoneCode: '672', latitude: -29.0333, longitude: 167.95 },
  "no": { countryCode: 'NO', countryName: 'Norway', currency: 'NOK', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '47', latitude: 62, longitude: 10 },
  "mp": { countryCode: 'MP', countryName: 'Northern Mariana Islands', currency: 'USD', continent: 'Oceania', subContinent: 'Micronesia', phoneCode: '1670', latitude: 15.2, longitude: 145.75 },
  "um": { countryCode: 'UM', countryName: 'United States Minor Outlying Islands', currency: 'USD', continent: 'Americas', subContinent: 'Northern America', phoneCode: 'null', latitude: 19.2833, longitude: 166.6 },
  "fm": { countryCode: 'FM', countryName: 'Micronesia', currency: 'USD', continent: 'Oceania', subContinent: 'Micronesia', phoneCode: '691', latitude: 6.9167, longitude: 158.25 },
  "mh": { countryCode: 'MH', countryName: 'Marshall Islands', currency: 'USD', continent: 'Oceania', subContinent: 'Micronesia', phoneCode: '692', latitude: 9, longitude: 168 },
  "pw": { countryCode: 'PW', countryName: 'Palau', currency: 'USD', continent: 'Oceania', subContinent: 'Micronesia', phoneCode: '680', latitude: 7.5, longitude: 134.5 },
  "pk": { countryCode: 'PK', countryName: 'Pakistan', currency: 'PKR', continent: 'Asia', subContinent: 'Southern Asia', phoneCode: '92', latitude: 30, longitude: 70 },
  "pa": { countryCode: 'PA', countryName: 'Panama', currency: 'PAB,USD', continent: 'Americas', subContinent: 'Central America', phoneCode: '507', latitude: 9, longitude: -80 },
  "pg": { countryCode: 'PG', countryName: 'Papua New Guinea', currency: 'PGK', continent: 'Oceania', subContinent: 'Melanesia', phoneCode: '675', latitude: -6, longitude: 147 },
  "py": { countryCode: 'PY', countryName: 'Paraguay', currency: 'PYG', continent: 'Americas', subContinent: 'South America', phoneCode: '595', latitude: -23, longitude: -58 },
  "pe": { countryCode: 'PE', countryName: 'Peru', currency: 'PEN', continent: 'Americas', subContinent: 'South America', phoneCode: '51', latitude: -10, longitude: -76 },
  "ph": { countryCode: 'PH', countryName: 'Philippines', currency: 'PHP', continent: 'Asia', subContinent: 'South-Eastern,Asia', phoneCode: '63', latitude: 13, longitude: 122 },
  "pn": { countryCode: 'PN', countryName: 'Pitcairn', currency: 'NZD', continent: 'Oceania', subContinent: 'Polynesia', phoneCode: '64', latitude: -24.36146, longitude: -128.3163761 },
  "pl": { countryCode: 'PL', countryName: 'Poland', currency: 'PLN', continent: 'Europe', subContinent: 'Eastern Europe', phoneCode: '48', latitude: 52, longitude: 20 },
  "pt": { countryCode: 'PT', countryName: 'Portugal', currency: 'EUR', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '351', latitude: 39.5, longitude: -8 },
  "gw": { countryCode: 'GW', countryName: 'Guinea-Bissau', currency: 'XOF', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '245', latitude: 12, longitude: -15 },
  "tl": { countryCode: 'TL', countryName: 'Timor-Leste', currency: 'USD', continent: 'Asia', subContinent: 'South-Eastern,Asia', phoneCode: '670', latitude: -8.5, longitude: 125.55 },
  "pr": { countryCode: 'PR', countryName: 'Puerto Rico', currency: 'USD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1787,1939', latitude: 18.25, longitude: -66.5 },
  "qa": { countryCode: 'QA', countryName: 'Qatar', currency: 'QAR', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '974', latitude: 25.5, longitude: 51.25 },
  "re": { countryCode: 'RE', countryName: 'Réunion', currency: 'EUR', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '262', latitude: -21.1, longitude: 55.6 },
  "ro": { countryCode: 'RO', countryName: 'Romania', currency: 'RON', continent: 'Europe', subContinent: 'Eastern Europe', phoneCode: '40', latitude: 46, longitude: 25 },
  "ru": { countryCode: 'RU', countryName: 'Russian Federation', currency: 'RUB', continent: 'Europe', subContinent: 'Eastern Europe', phoneCode: '7', latitude: 60, longitude: 100 },
  "rw": { countryCode: 'RW', countryName: 'Rwanda', currency: 'RWF', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '250', latitude: -2, longitude: 30 },
  "bl": { countryCode: 'BL', countryName: 'Saint Barthélemy', currency: 'EUR', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '590', latitude: 17.9, longitude: -62.8333 },
  "sh": { countryCode: 'SH', countryName: 'Saint Helena', currency: 'SHP', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '290', latitude: -15.9333, longitude: -5.7 },
  "kn": { countryCode: 'KN', countryName: 'Saint Kitts and Nevis', currency: 'XCD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1869', latitude: 17.3333, longitude: -62.75 },
  "ai": { countryCode: 'AI', countryName: 'Anguilla', currency: 'XCD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1264', latitude: 18.25, longitude: -63.1667 },
  "lc": { countryCode: 'LC', countryName: 'Saint Lucia', currency: 'XCD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1758', latitude: 13.8833, longitude: -61.1333 },
  "mf": { countryCode: 'MF', countryName: 'Saint Martin', currency: 'EUR', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '590', latitude: 18.05, longitude: -63.08 },
  "pm": { countryCode: 'PM', countryName: 'Saint Pierre and Miquelon', currency: 'EUR', continent: 'Americas', subContinent: 'Northern America', phoneCode: '508', latitude: 46.8333, longitude: -56.3333 },
  "vc": { countryCode: 'VC', countryName: 'Saint Vincent and the Grenadines', currency: 'XCD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1784', latitude: 13.25, longitude: -61.2 },
  "sm": { countryCode: 'SM', countryName: 'San Marino', currency: 'EUR', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '378', latitude: 43.7667, longitude: 12.4167 },
  "st": { countryCode: 'ST', countryName: 'São Tomé and Príncipe', currency: 'STD', continent: 'Africa', subContinent: 'Middle Africa', phoneCode: '239', latitude: 1, longitude: 7 },
  "sa": { countryCode: 'SA', countryName: 'Saudi Arabia', currency: 'SAR', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '966', latitude: 25, longitude: 45 },
  "sn": { countryCode: 'SN', countryName: 'Senegal', currency: 'XOF', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '221', latitude: 14, longitude: -14 },
  "rs": { countryCode: 'RS', countryName: 'Serbia', currency: 'RSD', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '381', latitude: 44, longitude: 21 },
  "sc": { countryCode: 'SC', countryName: 'Seychelles', currency: 'SCR', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '248', latitude: -4.5833, longitude: 55.6667 },
  "sl": { countryCode: 'SL', countryName: 'Sierra Leone', currency: 'SLL', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '232', latitude: 8.5, longitude: -11.5 },
  "sg": { countryCode: 'SG', countryName: 'Singapore', currency: 'SGD', continent: 'Asia', subContinent: 'South-Eastern,Asia', phoneCode: '65', latitude: 1.3667, longitude: 103.8 },
  "sk": { countryCode: 'SK', countryName: 'Slovakia', currency: 'XSU', continent: 'Europe', subContinent: 'Eastern Europe', phoneCode: '421', latitude: 48.6667, longitude: 19.5 },
  "vn": { countryCode: 'VN', countryName: 'Viet Nam', currency: 'VND', continent: 'Asia', subContinent: 'South-Eastern,Asia', phoneCode: '84', latitude: 16, longitude: 106 },
  "si": { countryCode: 'SI', countryName: 'Slovenia', currency: 'EUR', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '386', latitude: 46, longitude: 15 },
  "so": { countryCode: 'SO', countryName: 'Somalia', currency: 'SBD', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '252', latitude: 10, longitude: 49 },
  "za": { countryCode: 'ZA', countryName: 'South Africa', currency: 'SOS', continent: 'Africa', subContinent: 'Southern Africa', phoneCode: '27', latitude: -29, longitude: 24 },
  "zw": { countryCode: 'ZW', countryName: 'Zimbabwe', currency: 'ZWL', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '263', latitude: -20, longitude: 30 },
  "es": { countryCode: 'ES', countryName: 'Spain', currency: 'EUR', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '34', latitude: 40, longitude: -4 },
  "ss": { countryCode: 'SS', countryName: 'South Sudan', currency: 'SSP', phoneCode: '211', latitude: 4.85, longitude: 31.6 },
  "eh": { countryCode: 'EH', countryName: 'Western Sahara', currency: 'MAD,DZD,MRO', continent: 'Africa', subContinent: 'Northern Africa', phoneCode: '212', latitude: 24.5, longitude: -13 },
  "sd": { countryCode: 'SD', countryName: 'Sudan', currency: 'SDG', continent: 'Africa', subContinent: 'Northern Africa', phoneCode: '249', latitude: 15, longitude: 30 },
  "sr": { countryCode: 'SR', countryName: 'Suriname', currency: 'SRD', continent: 'Americas', subContinent: 'South America', phoneCode: '597', latitude: 4, longitude: -56 },
  "sj": { countryCode: 'SJ', countryName: 'Svalbard and Jan Mayen', currency: 'NOK', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '4779', latitude: 78, longitude: 20 },
  "sz": { countryCode: 'SZ', countryName: 'Swaziland', currency: 'SZL', continent: 'Africa', subContinent: 'Southern Africa', phoneCode: '268', latitude: -26.5, longitude: 31.5 },
  "se": { countryCode: 'SE', countryName: 'Sweden', currency: 'SEK', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '46', latitude: 62, longitude: 15 },
  "ch": { countryCode: 'CH', countryName: 'Switzerland', currency: 'CHE,CHF,CHW', continent: 'Europe', subContinent: 'Western Europe', phoneCode: '41', latitude: 47, longitude: 8 },
  "sy": { countryCode: 'SY', countryName: 'Syrian Arab Republic', currency: 'SYP', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '963', latitude: 35, longitude: 38 },
  "tj": { countryCode: 'TJ', countryName: 'Tajikistan', currency: 'TJS', continent: 'Asia', subContinent: 'Central Asia', phoneCode: '992', latitude: 39, longitude: 71 },
  "th": { countryCode: 'TH', countryName: 'Thailand', currency: 'THB', continent: 'Asia', subContinent: 'South-Eastern,Asia', phoneCode: '66', latitude: 15, longitude: 100 },
  "tg": { countryCode: 'TG', countryName: 'Togo', currency: 'XOF', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '228', latitude: 8, longitude: 1.1667 },
  "tk": { countryCode: 'TK', countryName: 'Tokelau', currency: 'NZD', continent: 'Oceania', subContinent: 'Polynesia', phoneCode: '690', latitude: -9, longitude: -172 },
  "to": { countryCode: 'TO', countryName: 'Tonga', currency: 'TOP', continent: 'Oceania', subContinent: 'Polynesia', phoneCode: '676', latitude: -20, longitude: -175 },
  "tt": { countryCode: 'TT', countryName: 'Trinidad and Tobago', currency: 'TTD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1868', latitude: 11, longitude: -61 },
  "ae": { countryCode: 'AE', countryName: 'United Arab Emirates', currency: 'AED', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '971', latitude: 24, longitude: 54 },
  "tn": { countryCode: 'TN', countryName: 'Tunisia', currency: 'TND', continent: 'Africa', subContinent: 'Northern Africa', phoneCode: '216', latitude: 34, longitude: 9 },
  "tr": { countryCode: 'TR', countryName: 'Turkey', currency: 'TRY', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '90', latitude: 39, longitude: 35 },
  "tm": { countryCode: 'TM', countryName: 'Turkmenistan', currency: 'TMT', continent: 'Asia', subContinent: 'Central Asia', phoneCode: '993', latitude: 40, longitude: 60 },
  "tc": { countryCode: 'TC', countryName: 'Turks and Caicos Islands', currency: 'USD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1649', latitude: 21.75, longitude: -71.5833 },
  "tv": { countryCode: 'TV', countryName: 'Tuvalu', currency: 'AUD', continent: 'Oceania', subContinent: 'Polynesia', phoneCode: '688', latitude: -8, longitude: 178 },
  "ug": { countryCode: 'UG', countryName: 'Uganda', currency: 'UGX', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '256', latitude: 1, longitude: 32 },
  "ua": { countryCode: 'UA', countryName: 'Ukraine', currency: 'UAH', continent: 'Europe', subContinent: 'Eastern Europe', phoneCode: '380', latitude: 49, longitude: 32 },
  "mk": { countryCode: 'MK', countryName: 'Macedonia', currency: 'MKD', continent: 'Europe', subContinent: 'Southern Europe', phoneCode: '389', latitude: 41.8333, longitude: 22 },
  "eg": { countryCode: 'EG', countryName: 'Egypt', currency: 'EGP', continent: 'Africa', subContinent: 'Northern Africa', phoneCode: '20', latitude: 27, longitude: 30 },
  "gb": { countryCode: 'GB', countryName: 'United Kingdom', currency: 'GBP', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '44', latitude: 54, longitude: -2 },
  "gg": { countryCode: 'GG', countryName: 'Guernsey', currency: 'GBP', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '44', latitude: 49.45, longitude: -2.58 },
  "je": { countryCode: 'JE', countryName: 'Jersey', currency: 'GBP', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '44', latitude: 49.2167, longitude: -2.1167 },
  "im": { countryCode: 'IM', countryName: 'Isle of Man', currency: 'GBP', continent: 'Europe', subContinent: 'Northern Europe', phoneCode: '44', latitude: 54.23, longitude: -4.57 },
  "tz": { countryCode: 'TZ', countryName: 'Tanzania', currency: 'TZS', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '255', latitude: -6, longitude: 35 },
  "us": { countryCode: 'US', countryName: 'United States', currency: 'USD,USN,USS', continent: 'Americas', subContinent: 'Northern America', phoneCode: '1', latitude: 38, longitude: -97 },
  "vi": { countryCode: 'VI', countryName: 'Virgin Islands (U.S.)', currency: 'USD', continent: 'Americas', subContinent: 'Caribbean', phoneCode: '1340', latitude: 18.3333, longitude: -64.8333 },
  "bf": { countryCode: 'BF', countryName: 'Burkina Faso', currency: 'XOF', continent: 'Africa', subContinent: 'Western Africa', phoneCode: '226', latitude: 13, longitude: -2 },
  "uy": { countryCode: 'UY', countryName: 'Uruguay', currency: 'UYI,UYU', continent: 'Americas', subContinent: 'South America', phoneCode: '598', latitude: -33, longitude: -56 },
  "uz": { countryCode: 'UZ', countryName: 'Uzbekistan', currency: 'UZS', continent: 'Asia', subContinent: 'Central Asia', phoneCode: '998', latitude: 41, longitude: 64 },
  "ve": { countryCode: 'VE', countryName: 'Venezuela', currency: 'VEF', continent: 'Americas', subContinent: 'South America', phoneCode: '58', latitude: 8, longitude: -66 },
  "wf": { countryCode: 'WF', countryName: 'Wallis and Futuna', currency: 'XPF', continent: 'Oceania', subContinent: 'Polynesia', phoneCode: '681', latitude: -13.3, longitude: -176.2 },
  "ws": { countryCode: 'WS', countryName: 'Samoa', currency: 'WST', continent: 'Oceania', subContinent: 'Polynesia', phoneCode: '685', latitude: -13.5833, longitude: -172.3333 },
  "ye": { countryCode: 'YE', countryName: 'Yemen', currency: 'YER', continent: 'Asia', subContinent: 'Western Asia', phoneCode: '967', latitude: 15, longitude: 48 },
  "zm": { countryCode: 'ZM', countryName: 'Zambia', currency: 'ZMK', continent: 'Africa', subContinent: 'Eastern Africa', phoneCode: '260', latitude: -15, longitude: 30 },
  "xs": { latitude: 10.1813335, longitude: 114.365081799, continent: 'Asia' }
};



data.state = {
  "AA": { name:"U.S. Armed Forces – Americas", latitude: 33.0364, longitude: -82.2493 }, // first of 2: http://usa.youbianku.com/category/state/AA
  "AE": { name:"U.S. Armed Forces – Europe", latitude:48.5, longitude: 23.383333 }, // geographical midpoint of Europe https://tools.wmflabs.org/geohack/geohack.php?pagename=Geographical_midpoint_of_Europe&params=48_30_N_23_23_E_type:landmark
  "AP": { name:"U.S. Armed Forces – Pacific", latitude: 33.2116, longitude: -97.0876 }, // first of 6: http://usa.youbianku.com/category/state/AP
	"AK": { name:"Alaska", latitude: 61.3850, longitude: -152.2683 },
	"AL": { name:"Alabama", latitude: 32.7990, longitude: -86.8073 },
	"AR": { name:"Arkansas", latitude: 34.9513, longitude: -92.3809 },
	"AS": { name:"American Samoa", latitude: 14.2417, longitude: -170.7197 },
	"AZ": { name:"Arizona", latitude: 33.7712, longitude: -111.3877 },
	"CA": { name:"California", latitude: 36.1700, longitude: -119.7462 },
	"CO": { name:"Colorado", latitude: 39.0646, longitude: -105.3272 },
	"CT": { name:"Connecticut", latitude: 41.5834, longitude: -72.7622 },
	"DC": { name:"District Of Columbia", latitude: 38.8964, longitude: -77.0262 },
	"DE": { name:"Delaware", latitude: 39.3498, longitude: -75.5148 },
	"FL": { name:"Florida", latitude: 27.8333, longitude: -81.7170 },
  "FM": { name:"Federated States Of Micronesia", latitude: 7.1383, longitude: 151.5031 },
	"GA": { name:"Georgia", latitude: 32.9866, longitude: -83.6487 },
  "GU": { name:"Guam", latitude: 39.0176, longitude: -122.0666 }, // http://usa.youbianku.com/category/state/GU
	"HI": { name:"Hawaii", latitude: 21.1098, longitude: -157.5311 },
	"IA": { name:"Iowa", latitude: 42.0046, longitude: -93.2140 },
	"ID": { name:"Idaho", latitude: 44.2394, longitude: -114.5103 },
	"IL": { name:"Illinois", latitude: 40.3363, longitude: -89.0022 },
	"IN": { name:"Indiana", latitude: 39.8647, longitude: -86.2604 },
	"KS": { name:"Kansas", latitude: 38.5111, longitude: -96.8005 },
	"KY": { name:"Kentucky", latitude: 37.6690, longitude: -84.6514 },
	"LA": { name:"Louisiana", latitude: 31.1801, longitude: -91.8749 },
	"MA": { name:"Massachusetts", latitude: 42.2373, longitude: -71.5314 },
	"MD": { name:"Maryland", latitude: 39.0724, longitude: -76.7902 },
	"ME": { name:"Maine", latitude: 44.6074, longitude: -69.3977 },
  "MH": { name:"Marshall Islands", latitude: 9, longitude: 168 },
	"MI": { name:"Michigan", latitude: 43.3504, longitude: -84.5603 },
	"MN": { name:"Minnesota", latitude: 45.7326, longitude: -93.9196 },
	"MO": { name:"Missouri", latitude: 38.4623, longitude: -92.3020 },
	"MP": { name:"Northern Mariana Islands", latitude: 14.8058, longitude: -145.5505 },
	"MS": { name:"Mississippi", latitude: 32.7673, longitude: -89.6812 },
	"MT": { name:"Montana", latitude: 46.9048, longitude: -110.3261 },
	"NC": { name:"North Carolina", latitude: 35.6411, longitude: -79.8431 },
	"ND": { name:"North Dakota", latitude: 47.5362, longitude: -99.7930 },
	"NE": { name:"Nebraska", latitude: 41.1289, longitude: -98.2883 },
	"NH": { name:"New Hampshire", latitude: 43.4108, longitude: -71.5653 },
	"NJ": { name:"New Jersey", latitude: 40.3140, longitude: -74.5089 },
	"NM": { name:"New Mexico", latitude: 34.8375, longitude: -106.2371 },
	"NV": { name:"Nevada", latitude: 38.4199, longitude: -117.1219 },
	"NY": { name:"New York", latitude: 42.1497, longitude: -74.9384 },
	"OH": { name:"Ohio", latitude: 40.3736, longitude: -82.7755 },
	"OK": { name:"Oklahoma", latitude: 35.5376, longitude: -96.9247 },
	"OR": { name:"Oregon", latitude: 44.5672, longitude: -122.1269 },
	"PA": { name:"Pennsylvania", latitude: 40.5773, longitude: -77.2640 },
	"PR": { name:"Puerto Rico", latitude: 18.2766, longitude: -66.3350 },
  "PW": { name:"Palau", latitude: 7.2257, longitude: 134.3622 }, // http://usa.youbianku.com/category/state/PW
	"RI": { name:"Rhode Island", latitude: 41.6772, longitude: -71.5101 },
	"SC": { name:"South Carolina", latitude: 33.8191, longitude: -80.9066 },
	"SD": { name:"South Dakota", latitude: 44.2853, longitude: -99.4632 },
	"TN": { name:"Tennessee", latitude: 35.7449, longitude: -86.7489 },
	"TX": { name:"Texas", latitude: 31.1060, longitude: -97.6475 },
	"UT": { name:"Utah", latitude: 40.1135, longitude: -111.8535 },
	"VA": { name:"Virginia", latitude: 37.7680, longitude: -78.2057 },
	"VI": { name:"Virgin Islands", latitude: 18.0001, longitude: -64.8199 },
	"VT": { name:"Vermont", latitude: 44.0407, longitude: -72.7093 },
	"WA": { name:"Washington", latitude: 47.3917, longitude: -121.5708 },
	"WI": { name:"Wisconsin", latitude: 44.2563, longitude: -89.6385 },
	"WV": { name:"West Virginia", latitude: 38.4680, longitude: -80.9696 },
	"WY": { name:"Wyoming", latitude: 42.7475, longitude: -107.2085 }
}



function country(code) {
	code = (code) ? code.toLowerCase() : '';
	return data.country[code];
}


function getCountryName(code) {
  code = (code) ? code.toLowerCase() : '';
	return data.country[code].countryName;
}
function state(code) {
	code = (code) ? code.toUpperCase() : '';
  return data.state[code];
}

module.exports.countries = Object.entries(data.country)
module.exports.states = Object.entries(data.state)

module.exports.getCountryName = getCountryName;
module.exports.country = country;
module.exports.state = state;

