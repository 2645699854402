import * as React from "react";
import {Grid,Typography, TextField} from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";


// assets
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";

const {MentaportTypes} = require( "@mentaport/sdk-web")
const geos = require('util/geos-major')

const ShowLocation = React.memo(({ props, handleInputChange }) => {
  
  if (props.locationDrop === MentaportTypes.LocationType.radius) {
    return (
      <>
        <GetLatLong props={props} handleInputChange={handleInputChange} />
        <GetRadius props={props} handleInputChange={handleInputChange} />
        {(props.location.items >= -10 ?
        ( <GetItemsCount props={props} handleInputChange={handleInputChange} />) : <> </>)}
      </>
    );
  } else if (props.locationDrop === MentaportTypes.LocationType.address) {
    return (
      <>
        <GetAddress props={props} handleInputChange={handleInputChange} />
        <GetCity props={props} handleInputChange={handleInputChange} />
        <GetState props={props} handleInputChange={handleInputChange} />
        <GetCountry props={props} handleInputChange={handleInputChange} />
        <GetZip props={props} handleInputChange={handleInputChange} />
       
        <GetRadius props={props} handleInputChange={handleInputChange} />
        {(props.location.items >= -10 ?
        ( <GetItemsCount props={props} handleInputChange={handleInputChange} />) : <> </>)}
      </>
    );
  // } else if (props.locationDrop === "zipcode") {
  //   return (
  //     <>
  //       <GetZip props={props} handleInputChange={handleInputChange} />
  //       <GetCity props={props} handleInputChange={handleInputChange} />
  //       <GetState props={props} handleInputChange={handleInputChange} />
  //       <GetCountry props={props} handleInputChange={handleInputChange} />
  //       <GetRadius props={props} handleInputChange={handleInputChange} />
  //       <GetItemsCount props={props} handleInputChange={handleInputChange} />
  //     </>
  //   );
  } else if (props.locationDrop === MentaportTypes.LocationType.city) {
    return (
      <>
        <GetCity props={props} handleInputChange={handleInputChange} />
        <GetState props={props} handleInputChange={handleInputChange} />
        <GetCountry props={props} handleInputChange={handleInputChange} />
        {(props.location.items >= -10 ?
        ( <GetItemsCount props={props} handleInputChange={handleInputChange} />) : <> </>)}
      </>
    );
  } else if (props.locationDrop === MentaportTypes.LocationType.state) {
    return (
      <>
        <GetState props={props} handleInputChange={handleInputChange} />
        <GetCountry props={props} handleInputChange={handleInputChange} />
        {(props.location.items >= -10?
        ( <GetItemsCount props={props} handleInputChange={handleInputChange} />) : <> </>)}
      </>
    );
  } else if (props.locationDrop === MentaportTypes.LocationType.country) {
    return (
      <>
        <GetCountry props={props} handleInputChange={handleInputChange} />
        {(props.location.items >=-10 ?
        ( <GetItemsCount props={props} handleInputChange={handleInputChange} />) : <> </>)}
      </>
    );
  } else if (props.locationDrop === MentaportTypes.LocationType.keywords) {
    return (
      <>
        <GetKeyword props={props} handleInputChange={handleInputChange} />

        <GetLatLong props={props} handleInputChange={handleInputChange} />
        
        <GetRadius props={props} handleInputChange={handleInputChange} />
       
           
        {(props.location.items >= -10 ?
        ( <GetItemsCount props={props} handleInputChange={handleInputChange} />) : <> </>)}
        <Fab size="medium" color="primary" aria-label="add" >
          <AddIcon onClick={props.addNewPosition} />
        </Fab>
        
      </>
    );
  }
  return (
    <Grid item xs={12} sm={4}>
      {" "}
    </Grid>
  );
});

const GetRadius = React.memo(({ props, handleInputChange }) => {
  return (
    <>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          type="number"
          id="radius"
          name="radius"
          label="Radius (meters)"
          variant="standard"
          onChange={handleInputChange}
          value={props.latLong.radius}
        />
      </Grid>
    </>
  );
});

const GetItemsCount = React.memo(({ props, handleInputChange }) => {
  return (
    <>
      <Grid item xs={12} >
        <FormControlLabel  control={
         <Checkbox
            checked={props.checkedItems}
            onChange={(event) => props.setCheckedItems(event.target.checked)}
            name="checked"
            color="primary"
          />
          }
        label="Specific amount of items available:"
        />
        {props.checkedItems ? (
          <TextField sx={{width:150}}
            type="number"
            id="items"
            name="items"
            // label="Items available (count)"
            fullWidth
            variant="standard"
            onChange={handleInputChange}
            value={props.location.items}
          />
        ) :<></> }
        </Grid>
    </>
  );
});

const GetLatLong = React.memo(({ props, handleInputChange }) => {
  return (
    <>
      <Grid item >
        <TextField
          required
          type="number"
          step="0.01"
          id="lat"
          name="latitude"
          label="Latitude"
          variant="standard"
          onChange={handleInputChange}
          value={props.latLong.latitude}
        />
      </Grid>
      <Grid item>
        <TextField
          required
          type="number"
          step="0.01"
          id="long"
          name="longitude"
          label="Longitude"
          variant="standard"
          onChange={handleInputChange}
          value={props.latLong.longitude}
        />
      </Grid>
    </>
  );
});

const GetZip = React.memo(({ props, handleInputChange }) => {
  return (
    <>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          id="zipcode"
          type="number"
          name="zipcode"
          label="Zip / Postal code"
          fullWidth
          autoComplete="shipping postal-code"
          variant="standard"
          onChange={handleInputChange}
          value={props.location.zipcode}
        />
      </Grid>
    </>
  );
});

const GetAddress = React.memo(({ props, handleInputChange }) => {
  return (
    <>
      <Grid item xs={12}>
        <TextField
          required
          id="address"
          name="address"
          label="Address"
          fullWidth
          autoComplete="address-line"
          variant="standard"
          onChange={handleInputChange}
          value={props.location.address}
        />
      </Grid>
    </>
  );
});

const GetCity = React.memo(({ props, handleInputChange }) => {
  return (
    <>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          key="cityDef"
          id="city"
          name="city"
          label="City"
          fullWidth
          autoComplete="shipping address-level2"
          variant="standard"
          onChange={handleInputChange}
          value={props.location.city}
          inputProps={{ style: { textTransform: "capitalize" } }}
        />
      </Grid>
    </>
  );
});

const GetState = React.memo(({ props, handleInputChange }) => {
  return (
    <>
      <Grid item xs={12} sm={4}>
      {props.location.country === 'us' ? (
         <FormControl fullWidth variant="standard">
         <InputLabel id="state-label" sx={{ mb: 1, ml:-2}}>State</InputLabel>
         <Select
           labelId="state-label"
           id="state"
           name="state"
           value={props.location.state}
           label="State"
           onChange={handleInputChange}
         >
           {geos.states.map((row) => (
             <MenuItem key={row[0]} value={row[0]} > {row[0]}</MenuItem>
         ))}
         
         </Select>
       </FormControl>
      )
      :
        <TextField
          required
          id="state"
          name="state"
          label="State/Province/Region"
          fullWidth
          variant="standard"
          onChange={handleInputChange}
          value={props.location.state}
          inputProps={{ style: { textTransform: "uppercase" } }}
        />
   }
      </Grid>
    </>
  );
});

const GetCountry = React.memo(({ props, handleInputChange }) => {

  return (
    <>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth variant="standard">
            <InputLabel id="country-label"sx={{ mb: 1, ml:-2}}>Country</InputLabel>
            <Select
              labelId="country-label"
              id="country"
              name="country"
              value={props.location.country}
              label="Country"  
              onChange={handleInputChange}
            >
              {geos.countries.map((row) => (
                <MenuItem key={row[0]} value={row[0]} > {row[1].countryName}</MenuItem>
            ))}
            
            </Select>
          </FormControl>
        {/* <TextField
          required
          id="country"
          name="country"
          label="Country"
          fullWidth
          autoComplete="shipping country"
          variant="standard"
          onChange={handleInputChange}
          value={props.location.country}
          inputProps={{ style: { textTransform: "uppercase" } }}
        /> */}
      </Grid>
    </>
  );
});

const GetKeyword = React.memo(({ props, handleInputChange }) => {
  return (
    <>
      <Grid item xs={12} sm={4}>
        <TextField
          required
          id="keyword"
          name="keyword"
          label="Location Name"
          fullWidth
          variant="standard"
          onChange={handleInputChange}
          value={props.location.keyword}
        />
      </Grid>
    </>
  );
});

export default function LocationTriggerForm(props) {
  function handleInputChange(e) {
    if(e.target.name === "longitude" || e.target.name === "latitude" || e.target.name === "radius") {
      props.handleChangeLatLong(e.target.value, e.target.name);
      return;
    }
    props.handleChangeLocation(e.target.value, e.target.name);
  }
  
  const theme = useTheme();

  // ---------------------------------------------------------

  return (
    <React.Fragment>
      <Typography variant="h3" gutterBottom color = { theme.palette.grey[700]} >
        {props.label}
      </Typography>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel id="location-label" sx={{ mt: 1, ml:-2}}>Where</InputLabel>
            <Select
              labelId="location-label"
              id="location-select"
              value={props.locationDrop}
              label="Location"   sx= {{fontSize: 15}}
              onChange={props.handleChangeLocationDrop}
            >
              <MenuItem value={MentaportTypes.LocationType.keywords}>keywords</MenuItem>
              <MenuItem value={MentaportTypes.LocationType.radius}>Lat/long</MenuItem>
              <MenuItem value={MentaportTypes.LocationType.address}>Address</MenuItem>
              {/* <MenuItem value={"zipcode"}>Zip</MenuItem> */}
              <MenuItem value={MentaportTypes.LocationType.city}>City</MenuItem>
              <MenuItem value={MentaportTypes.LocationType.state}>State</MenuItem>
              <MenuItem value={MentaportTypes.LocationType.country}>Country</MenuItem>
              <MenuItem value={MentaportTypes.LocationType.none}>Anywhere</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Button disableElevation sx={{ fontSize: '.9em'}}>
            Batch Upload (CSV)
            <ChevronRightOutlinedIcon />
          </Button>
        </Grid>
        <ShowLocation props={props} handleInputChange={handleInputChange} />
       
      </Grid>
    </React.Fragment>
  );
}
