
// project imports
import MainLayout from 'layout/MainLayout';
import { Navigate} from 'react-router-dom';
// page routing
import Home from "pages/Home";
import Dashboard from "pages/Dashboard";
import ContractMain from "pages/ContractMain";
import AddContract from "pages/AddContract";
import APIDoc from "pages/APIdoc";

import ComingSoon from "pages/ComingSoon";
import ComingSoonInternal from "pages/ComingSoon_";

// ==============================|| MAIN ROUTING ||============================== //


const MainRoutes = (isLoggedIn) => {
    const routes = {
        path: '/',
        // element:  <MainLayout /> ,
        element: isLoggedIn ? <MainLayout /> : <Navigate to={{ pathname: "/login" }} />,
        children: [
            {
                path: '/home',
                element: <Home />
            },
            {
                path: '/dashboard',
                element: <Dashboard />
            },
            {
                path: '/contract',
                element: <ContractMain />
            },
            {
                path: '/contract?',
                element: <ContractMain />
            },
            {
                path: '/newcontract',
                element: <AddContract />
            },
            {
                path: '/apidoc',
                element: <APIDoc />
            },
            {
                path: '/comingsoon',
                element: <ComingSoon />
            },
            {
                path: '/comingsoon_',
                element: <ComingSoonInternal />
            }
        ]
    }
    return routes;
};

 export default MainRoutes;
// const MainRoutes= () =>{
//     return (
//         <Routes>
//             <Route path="/" element={<MainLayout /> } >
//                 <Route path="/" element={ <Home />  } />
//             </Route>
//             <Route path="/" element={<MainLayout /> } >
//                 <Route path="/dashboard" element={<Dashboard />} />
//             </Route>
//             <Route path="/" element={<MainLayout /> } >
//                 <Route path="/newcontract" element={<AddContract />} />
//             </Route>
       
//         </Routes>
//     );
// }