const config = {
    basename: '',
    defaultPath: '/',
    fontFamily: `'Exo', sans-serif`,
    registerPath: '/waitlist'
};

export default config;
//fontFamily: `'Roboto', sans-serif`,
//fontFamily: `'exothin',exothin_italic, sans-serif`,
//"/register"