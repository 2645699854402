const {MentaportTypes} = require( "@mentaport/sdk-web")
const geos = require('./geos-major')

function GenerateTimeData(type, startDate, endDate) {
   
    var start = ""
    var end = ""
    
    if (type === MentaportTypes.TimesType.startTime) {
       start = startDate.toString('yyyy/MM/dd hh:mm a');
    } else if (type === MentaportTypes.TimesType.timeWindow) {
       start = startDate.toString('yyyy/MM/dd hh:mm a');
       end = endDate.toString('yyyy/MM/dd hh:mm a');
    }
    const time: MentaportTypes.Time = {
        idType: type,
        startTime : start,
        endTime: end
      };
    return time;
}

function GenerateAccessData(type, list) {
    const access: MentaportTypes.Access = {
        idType: type,
        whitelist : list
      };
    return access;
}

function GenerateLocationData(type, loc, itemCheck, listPos) {
     console.log("GenerateLocationData " )

     console.log(listPos)
    var info = ""
    var latitude = "";
    var longitude = "";
    var amount = -1;

    if(itemCheck) {
        amount = loc.items;
    }
    if (type === MentaportTypes.LocationType.keywords) {
        info = loc.keyword
    }
    else if (type === MentaportTypes.LocationType.radius) {
        info = "geopoint"
    } else if (type === MentaportTypes.LocationType.address) {
        info = loc.address + "," + loc.city + "," + loc.state + "," +loc.zipcode + "," + loc.country;
    } else if (type === MentaportTypes.LocationType.city) {
        info = loc.city + "," + loc.state +  "," + loc.country;
    } else if (type === MentaportTypes.LocationType.state) {
        info = loc.state + "," + loc.country;
        if(loc.country === 'us') {
            let gg = geos.state(loc.state);
            latitude = gg.latitude;
            longitude = gg.longitude;
        }
        listPos =[{latitude: latitude, longitude: longitude  }]
    } else if (type === MentaportTypes.LocationType.country) {
        info = loc.country;
        let  gg = geos.country(loc.country);
        console.log(gg)
        latitude = gg.latitude;
        longitude = gg.longitude;
        listPos =[{latitude: latitude, longitude: longitude  }]
    }
    const location: MentaportTypes.Location = {
        idType: type,
        info : info,
        coordinates:listPos,
        amount: amount
    };
    return location
}
export {GenerateTimeData, GenerateAccessData, GenerateLocationData}