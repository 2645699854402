
import React from 'react';
import { useTheme } from "@mui/material/styles";
import {Grid} from '@mui/material';


import Typography from '@mui/material/Typography';

function ComingSoonInternal() {
    const theme = useTheme();

    return (
        <Grid justifyContent="center" sx={{ flexGrow: 2 }}   container spacing={4}>  
            <Grid item xs={12} sx={{ m: 3 }} >
            <Typography variant="h1" component="div" align='center' color = { theme.palette.grey[700]}  >
                Coming Soon
            </Typography>

           </Grid>
            <Grid item xs={12} sx={{ m: 3 }}>
            <Typography variant="h2" component="div" align='center' color = { theme.palette.grey[700]}  >
                Currently we are doing your Smart Contract integations in house!
            </Typography>
          
           </Grid>
            <Grid item xs={12} sx={{ m: 3 }}>
                <Typography variant="h2" component="div"  align='center' color = { theme.palette.primary.main}>
                    "Bring your smart contracts to the real world"
                </Typography>
          
           </Grid>
           

        </Grid>
    );
}

export default ComingSoonInternal; 