
// project imports
import MinimalLayout from 'layout/MinimalLayout';

import AuthLogin from  'pages/auth/Login';
import AuthRegister from  'pages/auth/Register';
import Waitlist from  'pages/auth/Waitlist';

import { Navigate} from 'react-router-dom';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = (isLoggedIn ) => {
   const routes = {
        path: '/',
        element:  isLoggedIn ? <Navigate to={{ pathname: "/home" }} /> :<MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <AuthLogin />
            },
            // {
            //     path: '/waitlist',
            //     element: <Waitlist />
            // },
            // {
            //     path: '/register',
            //     element: <AuthRegister />
            // },
            {
                path: '/logout',
                element: <AuthLogin />
            },
            { path: '/', element: <Navigate to="/login" /> },
        ]
    };
   
    return routes;
};

export default AuthenticationRoutes;
