import * as React from "react";

import { CardContent, Typography } from "@mui/material";
import {Table, TableBody, TableCell,TableHead,TableRow} from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import Title from "ui-component/extended/Title";

import IconButton from "@mui/material/IconButton";


export default function SimpleList(props) {
  //const theme = useTheme();

  return (
    <MainCard content={false} boxShadow={true} sx={{mt:5,ml:2, width:400}}>
      <CardContent>
        <Title>{props.name}</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Latitude</TableCell>
              <TableCell>Longitude</TableCell> 
              <TableCell>Radius</TableCell> 
            </TableRow>
          </TableHead>
          <TableBody>
            {props.list.map((value) => (
              <TableRow key={value.latitude}>
                <TableCell>
                    <Typography gutterBottom>{value.latitude}</Typography> 
                </TableCell>
                <TableCell>
                    <Typography gutterBottom>{value.longitude}</Typography> 
                </TableCell>
                <TableCell>
                    <Typography gutterBottom>{value.radius}</Typography> 
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </MainCard>
  );
}
