// assets
import { IconPencil, IconPlaylistAdd } from '@tabler/icons';

// constant
const icons = {
    IconPencil,
    IconPlaylistAdd
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

function PagesContracts (contractsMap) {
  
    var chlildContract = [];
    if(contractsMap.length > 0) {
        contractsMap.forEach((value, key) => {
            const obj= {
                id: value.key,
                title: value.name,
                type: 'item',
                url: '/contract?'+value.key,
                info: value.info,
                iscontract: true
            }
            chlildContract.push(obj);
        })
    }

    const pages = {
        id: 'pages',
        title: 'Contracts',
        caption: '',
        type: 'group',
        children: [
            {
                id: 'new_contracts',
                title: 'Add New Contract',
                type: 'item',
                icon: icons.IconPlaylistAdd,
                 url: '/newcontract',
            },
            {
                id: 'live_contracts',
                title: 'My Contracts',
                type: 'collapse',
                icon: icons.IconPencil,
                children:chlildContract
            }
        ]
    };

    return pages;
}

const pages = {
    id: 'pages',
    title: 'Contracts',
    caption: '',
    type: 'group',
    children: [
        {
            id: 'new_contracts',
            title: 'Add New Contract',
            type: 'item',
            icon: icons.IconPlaylistAdd,
             url: '/newcontract',
            //url: '/comingsoon_',
        },
        {
            id: 'live_contracts',
            title: 'My Contracts',
            type: 'collapse',
            icon: icons.IconPencil,
            // url: '/newcontract',
            // children: [
            //    {
            //         id: 'tabler-icons',
            //         title: 'Mentaport Test',
            //         type: 'item',
            //         url: '/contract',
            //        // url: '/icons/tabler-icons',
            //        iscontract: false
            //    }, 
            //]
        }
    ]
};

export  {pages, PagesContracts};
//export default pages