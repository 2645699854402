import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Box, Grid} from '@mui/material/';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TelegramIcon from '@mui/icons-material/Telegram';

function Copyright() {//direction="row-reverse"
    return (
      <Typography variant="subtitle2" sx={{ mt: 2 }} color="#FFFF">
        {'Copyright © '}
        <Link color="inherit" href="https://www.mentaport.xyz" target="_blank" style={{ textDecoration: 'none' }}>
          Mentaport Inc
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
     
    );
}
function PrivacyP() {
    return (
      <Typography variant="subtitle2" sx={{ mt: 2 }} color="#FFFF">
        <Link color="inherit" href="https://www.mentaport.xyz/privacy-policy" target="_blank" style={{ textDecoration: 'none' }}>
          Privacy Policy
        </Link>
      </Typography>
    );
}
export default function Footer() {
  return (

    <Box component="div" display='flex' flex-direction= "column"
        sx={{ py: 3,
            backgroundColor: '#121213',
            backgroundImage: `url(${"/images/backgrounds/footer_background.png"})`

        }} >
       
        <Container  sx={{ mt: 2, mb:2, ml:3, mr:3 }} maxWidth={false}>
            <Link href="https://www.mentaport.xyz"  target="_blank" >
            <img src="/images/logo_white.png"  width={100} sx={{  display: { xs: 'none', md: 'flex' },  ml:2}} />   
            </Link>
            <Grid container  sx={{mb:1}} alignItems="right" justifyContent="right">
                <Link href="mailto:hello@mentaport.com?subject=Hello Mentaport"  target="_blank" >
                    <EmailIcon  sx={{mr:3}} color="neutral"  />
                </Link>
                <Link href="https://twitter.com/mentaportinc"  target="_blank" >              
                    <TwitterIcon  sx={{mr:3}} color="neutral"/>
                </Link>
                <Link href="https://www.linkedin.com/company/mentaport"  target="_blank" >
                    <LinkedInIcon  sx={{mr:3}} color="neutral"/>
                </Link>
                <Link href="https://t.me/+JHvdyOn6P_szZDRh"  target="_blank" >
                    <TelegramIcon  color="neutral"/>
                </Link>
            </Grid >
            <Divider light />
            <Grid container direction="row" justifyContent="flex"  alignItems="center">
                <Grid item item xs={4} md ={6} >  
                    <PrivacyP />
                </Grid>                          
                <Grid item item xs={8} md ={6} textAlign="right">                                    
                    <Copyright />
                </Grid>
            </Grid>
          
        </Container >
   
    </Box>
 
  );
}