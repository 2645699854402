// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions/types';

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    opened: true,
    contractOpen: false,
    contract:{
        name: "",
        code:"",
    },
    default: true
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;

    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.MENU_DEFAULT:
            return {
                ...state,
                default: action.default
            };
        case actionTypes.CONTRACT_OPEN:
            return {
                ...state,
                contractOpen: action.contractOpen
            };
        case actionTypes.SET_CONTRACT:
            return {
                ...state,
                contract : {
                    name: action.name,
                    code: action.info,
                }
            };
        default:
            return state;
    }
};

export default customizationReducer;
