
import {auth} from 'server/firebase'
import { onAuthStateChanged, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";

import { REGISTER_SUCCESS, REGISTER_ERROR, SIGNIN_SUCCESS, SIGNIN_ERROR, SIGNOUT_SUCCESS, SIGNOUT_ERROR,
    RESET_SUCCESS, RESET_ERROR, EMAIL_NOT_VERIFIED } from "./types";

// Signing in with Firebase
export const SignIn = (email, password, callback) => async dispatch => {
    try {
    
       signInWithEmailAndPassword(auth, email, password)
        .then(data => {
          console.log("jere 1")
            if(data.user) {
                if (data.user.emailVerified) {
                    console.log("IF", data.user.emailVerified);
                    dispatch({ type: SIGNIN_SUCCESS });
                    callback();
                } else {
                    console.log("ELSE", data.user.emailVerified);
                    dispatch({
                      type: EMAIL_NOT_VERIFIED,
                      payload: "You haven't verified your e-mail address."
                    });
                }
            } else {
                console.log("no user")
                dispatch({
                    type: SIGNIN_ERROR,
                    payload: "Invalid login credentials"
                });
            }
        })
        .catch((error) => {
       
          console.log("error "+ error.message)
          dispatch({
            type: SIGNIN_ERROR,
            payload: "Invalid login credentials"
          });
        });
    } catch (err) {
     console.log(err)
      dispatch({ type: SIGNIN_ERROR, payload: "Invalid login credentials" });
    }

  };

// Registerusedr with Firebase
export const RegisterNewUser = (email, password) => async dispatch => {
    try {
      createUserWithEmailAndPassword(auth, email, password)
        .then(dataBeforeEmail => {
          auth.onAuthStateChanged(function(user) {
                if (user) {
                    user.sendEmailVerification();
                    console.log("inside of sendEmailVerification");
                } else {
                    console.log("no user registr....")
                }
            });
        })
        .then(dataAfterEmail => {
          auth.onAuthStateChanged(function(user) {
                if (user) {
                    // Email is verified
                    dispatch({
                        type: REGISTER_SUCCESS,
                        payload:
                        "Your account was successfully created! Now you need to verify your e-mail address, please go check your inbox."
                    });
                } else {
                // Email is not verified
                dispatch({
                    type: REGISTER_ERROR,
                    payload:
                    "Something went wrong, we couldn't create your account. Please try again."
                });
            }
          });
        })
        .catch(function(error) {
          dispatch({
            type: REGISTER_ERROR,
            payload:
              "Something went wrong, we couldn't create your account. Please try again."
          });
        });
    } catch (err) {
      
      dispatch({
        type: REGISTER_ERROR,
        payload:
          "Something went wrong, we couldn't create your account. Please try again."
      });
    }
  };

// Signing out with Firebase
export const SignOut = () => async dispatch => {
    try {
        signOut(auth)
        .then(() => {
          dispatch({ type: SIGNOUT_SUCCESS });
        })
        .catch(() => {
          dispatch({
            type: SIGNOUT_ERROR,
            payload: "Error, we were not able to log you out. Please try again."
          });
        });
    } catch (err) {
      dispatch({
        type: SIGNOUT_ERROR,
        payload: "Error, we were not able to log you out. Please try again."
      });
    }
  };
  
  // Reset password with Firebase
  export const ResetPassword = (email) => async dispatch => {
    try {
        sendPasswordResetEmail(auth, email)
        .then(() =>
          dispatch({
            type: RESET_SUCCESS,
            payload:
              "Check your inbox. We've sent you a secured reset link by e-mail."
          })
        )
        .catch(() => {
          dispatch({
            type: RESET_ERROR,
            payload:
              "Oops, something went wrong we couldn't send you the e-mail. Try again and if the error persists, contact admin."
          });
        });
    } catch (err) {
      dispatch({ type: RESET_ERROR, payload: err });
    }
};