
var {MentaportSDK, MentaportTypes} = require( "@mentaport/sdk-web")

const client = new MentaportSDK({
  baseUrl: process.env.REACT_APP_BASE_URL,
  apiKey: process.env.REACT_APP_MENTAPORT_API_KEY
});

export async function generateNewContract(name, mintRules, dynamicRules) {
   console.log( process.env.REACT_APP_BASE_URL)
  //  client.getContractById("ULHirfpPe0NYLw2yqiaZ")
  // .then((p) => {
  //   console.log('Got contract successfully:');
  //   console.log(p);
  // }).catch(e => console.error('Error: ', e))

    // const access: MentaportTypes.Access = {
    //   idType: MentaportTypes.AccessType.public,
    //   whitelist : []
    // };
    // const time: MentaportTypes.Time = {
    //   idType: MentaportTypes.TimesType.none,
    //   startTime : "",
    //   endTime: ""
    // };
    // const location: MentaportTypes.Location = {
    //   idType: MentaportTypes.LocationType.radius,
    //   info : "50",
    //   latitude: 37.344,
    //   longitude: -122.322,
    //   amount: 100
    // };
  
    // const mintrules : MentaportTypes.ContractMintRules = {
    //   access: access,
    //   time: time,
    //   location: location
    // } 
    // const upgraderules: MentaportTypes.ContractUpgradeRules = {
    //   state:0,
    //   metadata:'',
    //   time: time,
    //   location:location
    // }
    
   const res = await client.generateNewContract(name, mintRules, dynamicRules)
    .then((p) => {
      console.log('generated contract successfully:');
      console.log(p);
      return p;
    }).catch(e =>  {
      console.error('Error: ', e);
    
      return JSON.parse(e);
    });
    
    return res;
}

export async function getContractMintRules(idkey) {
  const res = await client.getContractMintRules(idkey)
  .then((p) => {
    console.log('got mint rules successfully:');
    console.log(p);
    return p;
  }).catch(e =>  {
    console.error('Error: ', e);
    return JSON.parse(e);
  });
  return res;
}
export async function getNFTUpgradeRules(idkey) {
  const res = await client.getNFTUpgradeRules(idkey)
  .then((p) => {
    console.log('got upgrade rules successfully:');
    console.log(p);
    return p;
  }).catch(e =>  {
    console.error('Error: ', e);
    return JSON.parse(e);
  });

  return res;
}