import * as React from "react";
import {Grid, Typography} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const {MentaportTypes} = require( "@mentaport/sdk-web")

export default function ReviewTime(props) {
  const theme = useTheme();
  function Showtime() {

    if (props.timing === MentaportTypes.TimesType.timeWindow) {
      return (
        <>
         <Typography gutterBottom>Start Date: </Typography>
         <Typography gutterBottom> {props.startDate.toString('yyyy/MM/dd hh:mm a')} </Typography>

         <Typography gutterBottom>End Date: </Typography>
         <Typography gutterBottom> {props.endDate.toString('yyyy/MM/dd hh:mm a')} </Typography>
        </>
      );
    }
    if (props.timing === MentaportTypes.TimesType.startTime) {
      return (
        <>
        <Typography gutterBottom>Start Date: </Typography>
        <Typography gutterBottom> {props.startDate.toString('yyyy/MM/dd hh:mm a')} </Typography>
        </>
      );
    }
    return <>  <Typography gutterBottom>NA</Typography></>;
  }

  return (
    <Grid item xs={12}>
         <Typography variant="h3" gutterBottom sx={{ mt: 2 }} color = { theme.palette.grey[700]}>
          Time Triggers
        </Typography>
        <Showtime />
    </Grid>

  );
}
