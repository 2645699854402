
import { useSelector } from 'react-redux';
//

import './App.css';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

// routing
import Routes from './routes'

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';

const App = () => {
  const appState = useSelector((state) => state.appState);

  return (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(appState)}>
        <CssBaseline />
            <NavigationScroll>
          
                  <Routes />
             
            </NavigationScroll>
        </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;