import React from "react";
import { useEffect, useState } from "react";

import { useTheme } from "@mui/material/styles";
import {Grid} from "@mui/material";

import { FormControl, InputLabel, OutlinedInput } from "@mui/material";

// import {
//   mainListItems,
//   secondaryListItems,
// } from "components/dashboard/listItems";

import TotalIncomeMint from "components/dashboard/TotalIncomeMint";
import TotalInteractionLineChartCard from "components/dashboard/TotalInteractionLineChartCard";
import EarningCard from "components/dashboard/EarningCard";
import PopularCard from "components/dashboard/PopularCard";
import InteractionsCard from "components/dashboard/Interactions";
import MapCard from "components/dashboard/Map";
import { gridSpacing } from "store/constant";
//import {GetNFTWithinRegion} from 'server/Database';

const initialList = [];

const listNFTReducer = (state, action) => {
  // console.log(action.type, state);
  switch (action.type) {
    case "ADD_ITEM":
      return {
        ...state,
        listNFT: state.listNFT.concat({
          key: action.key,
          location: action.location,
        }),
      };
    default:
      throw new Error();
  }
};

function DashboardContent() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const inputlocation = React.createRef();
  // const mapRef = React.createRef()

  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, []);

  //-------------------------------------------------------------------------

  const [listNFTData, dispatchListData] = React.useReducer(listNFTReducer, {
    listNFT: initialList,
    isShowList: true,
  });

  function handleAdd(key, location) {
    dispatchListData({ type: "ADD_ITEM", key, location });
  }

  //-------------------------------------------------------------------------

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          {/* Total Interactions time series chart */}
          <Grid item lg={8} md={8} sm={8} xs={12}>
            <TotalInteractionLineChartCard isLoading={isLoading} />
          </Grid>
          {/* Total Volume */}
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <EarningCard isLoading={isLoading} />
          </Grid>
          {/* Primary and secondary sales  */}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TotalIncomeMint isLoading={isLoading} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          {/* Locations ranking */}
          <Grid item xs={12} md={5}>
            <PopularCard isLoading={isLoading} />
          </Grid>
          {/* Map */}
          <Grid item xs={12} md={7}>
            <FormControl fullWidth sx={{ mb: 1 }}>
              <InputLabel htmlFor="location-search">Location</InputLabel>
              <OutlinedInput
                id="location-search"
                inputRef={inputlocation}
                // value={values.amount}
                // onChange={handleChange('amount')}
                label="Location"
              />
            </FormControl>
            <MapCard
              listNFT={listNFTData.listNFT}
              locationRef={inputlocation}
              handleAdd={handleAdd}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={gridSpacing}>
          <Grid item xs={12} lg={12}>
            <InteractionsCard isLoading={isLoading} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
