// material-ui
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
//import { isLoaded, isEmpty } from 'react-redux-firebase'
import { useState, useEffect } from "react";

// project imports
import NavGroup from './NavGroup';
import  {menuItems, SetMenuItems} from 'menu-items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

async function GrabData(userId) {
    if(userId !== undefined) {
        const x = await SetMenuItems(userId);
        return x
    }
}

const MenuList = () => {
    //let items = menuItems.itemsDefault
    const [items, setItems] = useState(menuItems.itemsDefault);
    const appState = useSelector((state) => state.appState);
    const auth = useSelector(state => state.firebaseReducer.auth);

    useEffect(() => {
        GrabData(auth.uid).then((res) => {
            setItems(res.itemsDefault);
        });
    },[]);

    const navItems = items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
