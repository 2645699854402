import React,{ useState, useEffect,  useRef,} from 'react';
import { useSelector } from 'react-redux';

import { useTheme } from "@mui/material/styles";
import {Grid, Button, Typography,Box, Card, CardContent} from '@mui/material';
import { Link } from 'react-router-dom';
import LinearProgress from '@mui/material/LinearProgress';

import ListTriggers from "ui-component/ListTriggers";
import ListUsersWallets from "ui-component/ListUsersWallets";
import CodeDetailsForm from "components/contracts/CodeDetailsForm";

//------ SDK ---------
import {getContractMintRules, getNFTUpgradeRules} from "server/SDKCalls";
//--------------------

//------ DATABASE ---------
import {GetContractCode} from "server/Database";
//--------------------


async function GrabCodeData(codeInfo) {
    console.log('codeInfo ' +codeInfo)
    return await GetContractCode(codeInfo);
}

async function GrabData(contractId, rule) {
    console.log('contractId')
    
    if(rule === "mint") {
        return await getContractMintRules(contractId);
    }
    else if(rule === "upgrade") {
        return await getNFTUpgradeRules(contractId);
    }
}

function ContractMain() {
    const theme = useTheme();

    const appState = useSelector((state) => state.appState);
    const [mintRules, setMintRules] = React.useState([]);
    const [upgradeRules, setUpgradeRules] = React.useState([]);
    const [codeSnippets, setcodeSnippets] = React.useState({});
    const [loadingRules, setLoadingRules] = React.useState(true);
    const [name, setName] = React.useState(appState.contract.name);

    console.log(name + " " + appState.isOpen[0] + " " + loadingRules)
  
    useEffect(() => {
        setLoadingRules(true);
        GrabCodeData(appState.contract.code).then((res) => {
           
            if(res != null)
                setcodeSnippets(res)
        });

        setMintRules([])
        setUpgradeRules([])
        GrabData(appState.isOpen[0], "mint").then((res) => {
            console.log(res)
            if(res.status === "Success") {
                setMintRules(res.data);
            }
            GrabData(appState.isOpen[0], "upgrade").then((res) => {
                if(res.status === "Success") {
                    setUpgradeRules(res.data);
                }
                setLoadingRules(false);
            });
        });
    },[appState.contract]);
    
    function requestAPIKey() {
        alert("Coming soon")
    }
    function activateContract() {
        alert("Coming soon")
    }
   
    return (
        <>
        {loadingRules? (
        <React.Fragment>
            <Grid item xs={12} sx={{ m: 5, mb:15 }} >
                <Typography sx={{ mb: 2 }} variant="h2" component="div" align='left' color = { theme.palette.grey[700]}  >{"Contract: "}
                <Box sx={{ fontStyle: 'italic',  textTransform: "capitalize"  }} display='inline'>{name}</Box> 
                </Typography>

                <Button variant="contained" sx={{ mt: 3, ml: 1, minWidth:300 }} color="warning" onClick={activateContract}>
                {"Activate Contract"}
                </Button>
                <Button variant="contained" component={Link} to="/dashboard"  sx={{ mt: 3, ml: 4, minWidth:200  }}>
                {"Dashboard"}
                </Button>
            </Grid>
            <Grid item xs={12}   align='center' >
                <LinearProgress sx={{ justifyContent: 'center' ,  width: '70%'}}/> 
            </Grid >
        </React.Fragment>
        )
         :
            <Grid justifyContent="center" sx={{ flexGrow: 2 }}   container spacing={4}>  
                <Grid item xs={12} sx={{ mt: 5, ml: 5, mr: 5 }} >
                    <Typography sx={{ mb: 2 }} variant="h2" component="div" align='left' color = { theme.palette.grey[700]}  >{"Contract: "}
                    <Box sx={{ fontStyle: 'italic',  textTransform: "capitalize"  }} display='inline'>{name}</Box> 
                    </Typography>

                    <Button variant="contained" sx={{ mt: 3, ml: 1,  minWidth:300 }} color="warning" onClick={activateContract}>
                    {"Activate Contract"}
                    </Button>
                    <Button variant="contained" component={Link} to="/dashboard"  sx={{ mt: 3, ml:4, minWidth:200 }}>
                    {"Dashboard"}
                  </Button>
              </Grid>
            
                <Grid item xs={6}>
                    <Grid item  xs={12} sx={{ m: 0 }}>
                        <ListTriggers name ="Mint Trigger Rules" mainItem="Who" list={mintRules}  />
                    </Grid>
                    <Grid item  xs={12} sx={{ mt: 3 }}>
                        <ListTriggers  name ="Dynamic Upgrade Trigger Rules" mainItem="State" list={upgradeRules} />
                    </Grid>
                
                </Grid>
                <Grid item xs={6}>
                    <Grid item  xs={12} sx={{ m: 0 }}>
                        <ListUsersWallets list={[]} />
                        
                    </Grid>

                </Grid>
                
                <Grid item xs={12} sx={{ m: 0 }}>
                    <Card sx={{ ml:5, mr:5, mb:5}}>
                    <CardContent>
                        <CodeDetailsForm codeSnippets = {codeSnippets}/>
                        </CardContent>
                    </Card>
                </Grid>
            
            </Grid>
        } </>
    );
}

export default ContractMain; 