
import * as React from "react";
import { useTheme } from "@mui/material/styles";

import { CardContent, Typography } from "@mui/material";
import {Table, TableBody, TableCell,TableHead,TableRow} from "@mui/material";
import MainCard from "ui-component/cards/MainCard";


import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

import Title from "ui-component/extended/Title";
const {MentaportTypes} = require( "@mentaport/sdk-web")

export default function ListUsersWallets(props) {
  const theme = useTheme();

  const ShowTime = ({ time }) => {
   
    if (time.idType ===  MentaportTypes.TimesType.timeWindow) {
      return (
        <>
        <Typography gutterBottom>{`Start Date: ${time.startTime.toLocaleString()}`}</Typography>
        <Typography gutterBottom>{`End Date: ${time.endTime.toLocaleString()}`}</Typography>
        </>
      );
    }
    if (time.idType ===  MentaportTypes.TimesType.startTime) {
      return (
        <>
        <Typography gutterBottom>{`Start Date: ${time.startTime.toLocaleString()}`}</Typography>
        </>
      );
    }
    return <Typography gutterBottom>NA</Typography>;
  }

  const ShowLocation = ({ location }) => {

    if (location.idType ===  MentaportTypes.LocationType.radius) {
      const address = location.latitude + ", " + location.longitude;
      const address1 = "radius: " + location.info;
      return (
        <>
          <Typography>{address}</Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {address1}
          </Typography>
        </>
      );
    }
    
      const address = location.info;
      const address1 = "";

      return (
        <>
          <Typography>{address}</Typography>
          <Typography variant="caption" display="block" gutterBottom>
            {address1}
          </Typography>
        </>
      );
  };

  return (
    <MainCard content={false} boxShadow={true} sx={{m:5}}>
      <CardContent>
        <Title>Users Interations</Title>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Wallet</TableCell> 
              <TableCell>NFT </TableCell>
              <TableCell>Where</TableCell>
              <TableCell>When</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.list.map((value) => (
              <TableRow key={value.id}>
              {value.access!== undefined ? (
                <TableCell>{value.access.idType}</TableCell>) :
                <>
                <TableCell>
                  <Typography>{value.state}</Typography>
                  <Typography variant="caption" display="block">{`Metadata:`}</Typography>
                  <Typography variant="caption" display="block">{value.metadata}</Typography>
                </TableCell> 
                </>
              }

                <TableCell>
                  <ShowTime time={value.time} />
                </TableCell>
                <TableCell>
                  <ShowLocation location={value.location} />
                  {value.location.amount >=0 ? (
                   <Typography gutterBottom>{`Amount: ${value.location.amount}`}</Typography>
                  ) : <></>}
                </TableCell>
                <TableCell>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </MainCard>
  );
}
