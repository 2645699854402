import { createStore, compose , applyMiddleware} from 'redux';
import rootReducer from './reducer';
import reduxThunk from "redux-thunk";

// ==============================|| REDUX - MAIN STORE ||============================== //

const initialState = {}
const store = createStore(rootReducer, initialState,  applyMiddleware(reduxThunk));


const persister = 'Mentaport';

export { store, persister };
