import * as React from "react";
import { useTheme } from "@mui/material/styles";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "ui-component/extended/Title";
import MainCard from "ui-component/cards/MainCard";
import {
  Link,
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

// assets
import EthIcon from "../icons/EthIcon";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";

// Generate Order Data
function createData(
  id,
  type,
  item,
  amount,
  us_amount,
  account_from,
  account_to,
  location,
  date
) {
  return {
    id,
    type,
    item,
    amount,
    us_amount,
    account_from,
    account_to,
    location,
    date,
  };
}

const rows = [
  createData(
    0,
    "Discovery on mobile",
    "#t47833",
    "--",
    "--",
    "Mentaport",
    "M09786",
    "SoHo, New York City, NY",
    "11-Aug-2022 10:19:59"
  ),
  createData(
    1,
    "Transfer",
    "#t17847",
    "--",
    "--",
    "Tassmo",
    "M02133",
    "--",
    "11-Aug-2022 10:17:02"
  ),
  createData(
    2,
    "Sale",
    "#t87504",
    "1.9",
    "2102.80",
    "M19200",
    "Ramone",
    "--",
    "11-Aug-2022 10:16:32"
  ),
  createData(
    3,
    "Discovery on mobile",
    "#t09834",
    "--",
    "--",
    "M19200",
    "Ramone",
    "Twin Peaks, San Francisco, CA",
    "11-Aug-2022 10:14:30"
  ),
  createData(
    4,
    "Mint",
    "#t08887",
    "0.25",
    "275.30",
    "Mentaport",
    "DicoBoyz",
    "Midtown, New York City, NY",
    "11-Jul-2022 10:08:20"
  ),
  createData(
    5,
    "Sale",
    "#t87504",
    "2.03",
    "2300.50",
    "LumaluX",
    "Nomicon",
    "--",
    "11-Aug-2022 10:08:10"
  ),
  createData(
    6,
    "Discovery on mobile",
    "#t04354",
    "--",
    "--",
    "Mentaport",
    "M10231",
    "SoHo, New York City, NY",
    "11-Aug-2022 10:04:19"
  ),
  createData(
    7,
    "Discovery on mobile",
    "#t03212",
    "--",
    "--",
    "SuperFanz",
    "Florian888",
    "Las Vegas Boulevard, Las Vegas, NV",
    "11-Aug-2022 10:04:02"
  ),
  createData(
    8,
    "Sale",
    "#t37632",
    "1.92",
    "2322.80",
    "QRD989",
    "M32456",
    "--",
    "11-Jul-2022 10:02:22"
  ),
  createData(
    9,
    "Discovery on mobile",
    "#t09834",
    "--",
    "--",
    "Mentaport",
    "Lolol2",
    "Twin Peaks, San Francisco, CA",
    "11-Aug-2022 10:01:30"
  ),
  createData(
    10,
    "Discovery on mobile",
    "#t08887",
    "--",
    "--",
    "Mentaport",
    "AnaBan",
    "Upper East Side, New York City, NY",
    "11-Aug-2022 09:59:10"
  ),
];

function preventDefault(event) {
  event.preventDefault();
}

export default function Interactions() {
  const theme = useTheme();

  return (
    <MainCard content={false} boxShadow={true}>
      <CardContent>
        <Typography
          variant="h3"
          sx={{ ml: 1 }}
          color={theme.palette.primary[200]}
        >
          Recent Events
        </Typography>
        <Typography
          variant="h5"
          sx={{ m: 2 }}
          color={theme.palette.primary[200]}
        >
          Across all locations
        </Typography>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 900 }}>Event</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>Item</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>Price (ETH)</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>Price (USD)</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>From</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>To</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>Location</TableCell>
              <TableCell sx={{ fontWeight: 900 }}>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.item}</TableCell>
                {row.amount !== "--" ? (
                    <TableCell><EthIcon color="primary" sx={{ fontSize: 12 }} />{row.amount}</TableCell>
                  ) : (
                    <TableCell>{row.amount}</TableCell>
                  )}
                {row.us_amount !== "--" ? (
                    <TableCell>${row.us_amount}</TableCell>
                  ) : (
                    <TableCell>{row.us_amount}</TableCell>
                  )}
                <TableCell>{row.account_from}</TableCell>
                <TableCell>{row.account_to}</TableCell>
                <TableCell>{row.location}</TableCell>
                <TableCell>{row.date}</TableCell>
                {/* <TableCell align="right">{`$${row.amount}`}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardActions sx={{ p: 1.25, pt: 0, justifyContent: "right" }}>
        <Button size="h5" disableElevation>
          View all interactions
          <ChevronRightOutlinedIcon />
        </Button>
      </CardActions>
    </MainCard>
  );
}
